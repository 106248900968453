// Terms.jsx
import React, { useEffect, useRef } from 'react';
import './Terms.css';

const Terms = ({ isOpen, onClose, termsButtonRef }) => {
  const closeButtonRef = useRef(null);

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent background scrolling when the popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Focus the close button for accessibility
      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Return focus to the terms button when closing
  const handleClose = () => {
    onClose();
    if (termsButtonRef && termsButtonRef.current) {
      termsButtonRef.current.focus();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='terms'>
      <div className="popup-overlay" onClick={handleClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          <button
            className="close-button"
            onClick={handleClose}
            ref={closeButtonRef}
            aria-label="Close"
          >
            &times;
          </button>
          <h2>Terms and Conditions</h2>
          <h3>1. Introduction</h3>
                <p><strong>1.1 Parties.</strong> These Terms of Service (“Terms”) are entered into by and between <strong>MarketCrunch AI, Inc.</strong>, a Delaware C-Corporation (“MarketCrunch AI,” “we,” “us,” or “our”) and you (“you,” “user,” or “subscriber”). If you use the Service on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.</p>

                <p><strong>1.2 Updates.</strong> We reserve the right to modify these Terms at any time by posting updated Terms on our website or through the Service. Your continued use of the Service following any such update constitutes your acceptance of the revised Terms. Unless otherwise required by law, changes to these Terms are not retroactive.</p>

                <p><strong>1.3 No Financial Advice.</strong> MarketCrunch AI is <em>not</em> a registered broker-dealer, financial advisor, or investment adviser. All predictions, insights, or data provided are generated by artificial intelligence for <em>informational purposes only</em>. We do <em>not</em> provide personalized investment advice or recommendations. You should not make investment decisions without seeking advice from a qualified financial professional.</p>

                <br/>

                <h3>2. Use of the Service</h3>
                <p><strong>2.1 License Grant.</strong> Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to use our online platform, website, and related services (collectively, the “Service” or “Platform”). Your user account and access credentials are personal to you and may not be shared or transferred. You must notify us immediately if you become aware of any unauthorized use of your account.</p>

                <p><strong>2.2 Prohibited Activities.</strong> You agree <em>not</em> to:
                <ul>
                  <li>Use the Service in any way that infringes upon any intellectual property rights or violates any data privacy laws;</li>
                  <li>Reverse-engineer, disassemble, decompile, or otherwise attempt to extract the Service’s source code;</li>
                  <li>Access or use the Service from jurisdictions or by persons where such access is restricted or prohibited by law.</li>
                </ul>
                </p>

                <p><strong>2.3 Feedback.</strong> If you provide any feedback, comments, or suggestions to MarketCrunch AI regarding the Service, you grant us a worldwide, perpetual, irrevocable, royalty-free license to use and incorporate such feedback into our Service. We will not use your feedback to disclose your confidential information.</p>

                <p><strong>2.4 Third-Party Services.</strong> The Service may rely on or interface with third-party services (e.g., payment processors, analytics providers). MarketCrunch AI is not liable for the performance or non-performance of such third parties, and your use of those services may be governed by separate terms.</p>

                <br/>

                <h3>3. Content and Data</h3>
                <p><strong>3.1 Inputs and Outputs.</strong> You may provide inputs (e.g., stock tickers, parameters, or other data) to generate outputs (e.g., AI-generated predictions). You own the rights to any AI-generated outputs, subject to your compliance with these Terms.</p>

                <p><strong>3.2 Data Collection.</strong> We may collect anonymized and aggregated usage data to improve and optimize the Service. We will not disclose your personal data to third parties unless (a) required by applicable law or court order, or (b) in an aggregated and anonymized form that does not identify you individually.</p>

                <p><strong>3.3 Data License.</strong> You retain all rights to your User Input and personal data, but you grant MarketCrunch AI a non-exclusive, worldwide, royalty-free license to use, process, store, and transmit such data as is reasonably necessary to provide the Service.</p>

                <br/>

                <h3>4. Subscription Plans and Payments</h3>
                <p><strong>4.1 Credits.</strong> Predictions on the Platform require credits, with one prediction costing one credit. Free-tier users receive a set number of credits at specified intervals (e.g., weekly). Unused credits do not roll over unless explicitly stated. Additional credits may be purchased.</p>

                <p><strong>4.2 Subscription Tiers.</strong>
                <ul>
                  <li><strong>Free:</strong> Limited weekly predictions with basic features.</li>
                  <li><strong>Paid:</strong> Paid subscription with increased feature access and/or reduced unit credit price or similar added benefts</li>
                </ul>
                </p>

                <p><strong>4.3 Payments &amp; Refunds.</strong> All fees are stated in U.S. dollars and are non-refundable, except as required by law. Subscriptions automatically renew unless canceled prior to the renewal date. You are responsible for updating your payment information to avoid service interruptions.</p>

                <br/>

                <h3>5. Warranty and Disclaimer</h3>
                <p><strong>5.1 No Warranty of Accuracy.</strong> MarketCrunch AI is provided on an <em>“as is”</em> and <em>“as available”</em> basis. We make <strong>no warranties</strong>, express or implied, regarding the accuracy, completeness, or reliability of any predictions, insights, or other data. All warranties, including merchantability, fitness for a particular purpose, and non-infringement, are disclaimed to the fullest extent permitted by law.</p>

                <p><strong>5.2 User Responsibility.</strong> You acknowledge and agree that the Service’s outputs are <em>informational only</em> and may contain errors or omissions. You are <strong>solely responsible</strong> for validating any predictions before using them for financial or investment decisions. Always seek independent professional advice before acting on any information provided by MarketCrunch AI.</p>

                <p><strong>5.3 Service Availability.</strong> We do not guarantee uninterrupted or error-free access to the Service. Downtime may occur for maintenance, updates, or due to technical issues. MarketCrunch AI shall not be liable for any unavailability or disruption of the Service.</p>

                <br/>

                <h3>6. Limitation of Liability</h3>
                <p><strong>6.1 Indirect or Consequential Damages.</strong> In <strong>no event</strong> shall either party be liable for any indirect, incidental, special, or consequential damages, including but not limited to lost profits or business opportunities, even if such damages were foreseeable.</p>

                <p><strong>6.2 Liability Cap.</strong> MarketCrunch AI’s total liability under these Terms, for any cause of action arising out of or related to your use of the Service, will <strong>not exceed</strong> the greater of (a) twelve thousand U.S. dollars ($12,000) or (b) the amount you paid MarketCrunch AI in the twelve (12) months preceding the event giving rise to the claim.</p>

                <p><strong>6.3 No Fiduciary Relationship.</strong> MarketCrunch AI disclaims any fiduciary duties or liability for your financial or investment decisions. Your use of the Service is at <strong>your own risk</strong>.</p>

                <br/>

                <h3>7. Indemnification</h3>
                <p><strong>7.1 Your Indemnification Obligations.</strong> You agree to defend, indemnify, and hold harmless MarketCrunch AI, its officers, directors, employees, agents, and affiliates from and against any and all claims, liabilities, damages, losses, and expenses (including reasonable attorneys’ fees and costs) arising out of or in any way connected with:
                <ul>
                  <li>Your access to or use of the Service;</li>
                  <li>Your violation of these Terms or any applicable law;</li>
                  <li>Your infringement of any third-party rights, including intellectual property, privacy, or other proprietary rights.</li>
                </ul>
                </p>

                <p><strong>7.2 Procedure.</strong> MarketCrunch AI will promptly notify you of any claim for which it seeks indemnification and will provide you with reasonable cooperation in defending the claim. You may not settle any claim in a manner that requires MarketCrunch AI to admit fault, pay money, or take (or refrain from taking) any action without our prior written consent.</p>

                <br/>

                <h3>8. Termination</h3>
                <p><strong>8.1 Termination by You.</strong> You may discontinue use of the Service at any time. If you terminate a paid subscription before the end of its term, you will not be entitled to a refund, except as required by law.</p>

                <p><strong>8.2 Termination by MarketCrunch AI.</strong> We may suspend or terminate your access to the Service at any time if we believe you have violated these Terms or if we decide, in our sole discretion, to discontinue the Service. We are not liable to you for any damages arising from termination of access.</p>

                <p><strong>8.3 Effect of Termination.</strong> Upon termination, all licenses and rights granted to you in these Terms shall immediately cease. You must delete or destroy all confidential information obtained from the Service. Sections related to confidentiality, limitation of liability, indemnification, and dispute resolution shall survive any termination.</p>

                <br/>

                <h3>9. Confidentiality</h3>
                <p><strong>9.1 Mutual Obligations.</strong> Both parties agree to keep confidential any non-public information disclosed by the other party that is reasonably understood to be confidential or proprietary (“Confidential Information”). Each party shall protect the other’s Confidential Information using the same degree of care it uses to protect its own, but not less than reasonable care.</p>

                <p><strong>9.2 Legal Requirements.</strong> If either party is required to disclose Confidential Information by law, it will provide prompt notice to the other party (if permitted by law) and cooperate in any attempt to seek protective measures.</p>

                <br/>

                <h3>10. General Terms</h3>
                <p><strong>10.1 Assignment.</strong> Neither party may assign these Terms without the other party’s prior written consent, except to an affiliate or in connection with a merger, acquisition, or sale of substantially all assets.</p>

                <p><strong>10.2 Use of Subcontractors.</strong> MarketCrunch AI may use subcontractors or third-party service providers to deliver any part of the Service. MarketCrunch AI remains responsible for their compliance with these Terms.</p>

                <p><strong>10.3 Force Majeure.</strong> Neither party will be liable for any delays or failures to perform due to events beyond its reasonable control, such as natural disasters, government actions, war, labor disputes, or internet disturbances.</p>

                <p><strong>10.4 Notices.</strong> All legal notices must be in writing. Notices to MarketCrunch AI should be sent to <em>[Insert Contact Email]</em>. We will send notices to the email address associated with your account.</p>

                <p><strong>10.5 Compliance with Laws.</strong> You agree to comply with all applicable U.S. and international laws and regulations, including export control, anti-terrorism, and other relevant laws. You represent that you are not located in and do not represent any individual or entity in a restricted or embargoed jurisdiction.</p>

                <p><strong>10.6 Entire Agreement.</strong> These Terms, together with any referenced policies, constitute the <em>entire agreement</em> between the parties regarding the Service and supersede all prior or contemporaneous agreements.</p>

                <p><strong>10.7 No Waiver.</strong> Any delay or failure by either party to exercise a right or remedy does not constitute a waiver of that right or remedy.</p>

                <br/>

                <h3>11. Dispute Resolution and Governing Law</h3>
                <p><strong>11.1 Governing Law.</strong> These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, USA, without giving effect to any conflict of laws principles. The United Nations Convention on Contracts for the International Sale of Goods does not apply.</p>

                <p><strong>11.2 Arbitration.</strong> All disputes arising out of or relating to these Terms shall be resolved by <strong>binding arbitration</strong> administered by the American Arbitration Association (“AAA”) in Delaware. The proceedings shall be conducted in English.</p>

                <p><strong>11.3 Exceptions to Arbitration.</strong> Either party may seek injunctive or equitable relief in a court of competent jurisdiction to protect its Confidential Information or intellectual property rights.</p>

                <p><strong>11.4 Class Action Waiver.</strong> All claims must be brought on an individual basis, and class actions or collective claims are expressly waived.</p>

                <p><strong>11.5 Survival.</strong> Provisions relating to confidentiality, indemnification, limitation of liability, and dispute resolution shall survive the termination or expiration of these Terms.</p>

                <br/>

                <h3>12. Communication and Marketing</h3>
                <p><strong>12.1 Transactional Emails.</strong> By creating an account, you consent to receive transactional emails regarding your account, including but not limited to usage notifications, security alerts, and updates on Service-related features.</p>

                <p><strong>12.2 Promotional Emails.</strong> You may also receive promotional emails or marketing communications about new features or offers. You can opt out at any time by following the unsubscribe instructions included in each email.</p>

                <br/>

                <p>By using MarketCrunch AI, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with these Terms, you must discontinue use of the Service immediately.</p>

                <p><strong>Last Updated:</strong> Nov 01, 2024</p>

        </div>
      </div>
    </div>
  );
};

export default Terms;
