import React, { useState, useEffect, useRef } from "react";
import {
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  XIcon,
} from "react-share";
import "./Dashboard.css";
import { FaBars, FaTimes, FaHeart } from "react-icons/fa"; // Importing icons
import { auth } from "../firebase-config";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import SearchBox from "./SearchBox";
import HistoryPage from "./HistoryPage";
import UpgradePopup from "./UpgradePopup";
import companyTickers from "../company_tickers.json";
import PredictionOutput from "./PredictionOutput";
import Footer from "./Footer";
import { handleGoogleSignIn } from "./LoginPage";
import TrendingPage from "./TrendingPage";
import {
  FiTrendingUp,
  FiClock,
  FiPieChart,
  FiStar,
  FiLink,
  FiHeart,
  FiUser,
  FiLogOut,
} from "react-icons/fi";
import AccountPage from "./AccountPage";
import axios from "axios";
import { logUserActivity } from "./logActivity";
import Lottie from "lottie-react";
import databaseAnimation from "../assets/animations/prediction.json";
import cleaningAnimation from "../assets/animations/reddit_comment.json";
import predictionAnimation from "../assets/animations/neural_network.json";
import neuralNetworkAnimation from "../assets/animations/bulb.json";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TechnicalIndicatorsPlot from "./TechnicalIndicatorsPlot"; // Import TechnicalIndicatorsPlot

const pageTypeMapping = {
  "/": "HME",
  "/home": "HME",
  "/dashboard/history": "HIS",
  "/analyze": "AZE",
  "/dashboard": "DASH",
  "/dashboard/trending": "TRE",
  "/progress": "PRO",
  "/dashboard/ai-picks": "SCR",
};

const baseURL =
  process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
    ? "http://127.0.0.1:5000"
    : "https://flask-backend-52245432644.us-central1.run.app";

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

const calculateConfidence = (std_dev, predicted_change) => {
  const coefficient_of_variation = Math.abs(std_dev / predicted_change);
  return 100 - coefficient_of_variation * 100;
  // if (coefficient_of_variation < 0.3) {
  //   return 100 - (coefficient_of_variation / 0.3) * 33;
  // } else if (coefficient_of_variation < 0.6) {
  //   return 67 - ((coefficient_of_variation - 0.3) / 0.3) * 34;
  // } else {
  //   return Math.max(0, 33 - (coefficient_of_variation - 0.6) * 33);
  // }
};

const calculateAccuracy = (accuracy) => {
  if (accuracy.toString() === "-1" || accuracy === undefined) {
    return -1;
  }
  return 100 - Number(accuracy) * 100;
};

const parsePredictionData = (predictionData) => {
  try {
    const parsedData =
      typeof predictionData === "string"
        ? JSON.parse(predictionData)
        : predictionData;

    const std_dev = Number(parsedData.std_deviation);
    const predicted_change = Number(
      parsedData.next_day_change_percent.replace("%", "")
    );
    const confidence = calculateConfidence(std_dev, predicted_change);
    const margin = 100 - confidence;
    let accuracy = -1;
    if (parsedData.accuracy !== undefined) {
      accuracy = calculateAccuracy(parsedData.accuracy);
    }
    return {
      parsedData,
      confidence,
      margin,
      accuracy,
    };
  } catch (error) {
    console.error("Error parsing prediction data:", error);
    return null;
  }
};

const handleCopyLink = () => {
  const pid = (() => {
    const predictionData = localStorage.getItem("predictionData");
    if (predictionData) {
      try {
        return JSON.parse(predictionData)?.prediction_id || "unknown";
      } catch (error) {
        console.error("Error parsing predictionData from localStorage:", error);
        return "unknown";
      }
    } else {
      const currentUrl = window.location.href;
      return new URL(currentUrl).searchParams.get("pid") || "unknown";
    }
  })();

  const linkToCopy = `https://marketcrunch.ai/dashboard?pid=${pid}`;
  navigator.clipboard
    .writeText(linkToCopy)
    .then(() => {
      alert("Link copied to clipboard!");
      // Log the activity for page mapping as SH_L
      const userId =
        localStorage.getItem("uid") || localStorage.getItem("anonUserId");
      logUserActivity(userId, "SH_L");
    })
    .catch((error) =>
      console.error("Failed to copy link to clipboard:", error)
    );
};

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState("Loading...");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [parsedPrediction, setParsedPrediction] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);
  const [fetchError, setFetchError] = useState(false); // For tracking errors
  const [isMenuOpen, setMenuOpen] = useState(false); // State for mobile menu
  const navigate = useNavigate();
  const location = useLocation();
  const { prediction } = location.state || {};
  const [anonUserId, setAnonUserId] = useState(
    localStorage.getItem("anonUserId") || null
  );
  const [dayBeforeFormatted, setDayBeforeFormatted] = useState(null);
  const [weekBeforeFormatted, setWeekBeforeFormatted] = useState(null);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // Popup visibility state
  const closeButtonRef = useRef(null);
  const disclaimerButtonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [authLoading, setAuthLoading] = useState(false); // For authentication
  const [dataLoading, setDataLoading] = useState(false); // For data fetching
  const [progressLoading, setProgressLoading] = useState(false); // For progress bar simulation
  const params = new URLSearchParams(location.search);
  const [confidenceMeterMargin, setConfidenceMeterMargin] = useState(-7);
  const [accuracyMeterMargin, setAccuracyMeterMargin] = useState(-7);
  const [isInFavorites, setIsInFavorites] = useState(false);
  const pid = params.get("pid"); // Get the prediction ID from URL
  const urlActiveTab = params.get("activeTab"); // Get the prediction ID from URL
  const ticker = params.get("t");
  const [activeTab, setActiveTab] = useState(urlActiveTab || "analyze");
  const eventSourceRef = useRef(null);
  const width = useWindowWidth();
  const [isFavorite, setIsFavorite] = useState(false);

  const getAnimationForStage = () => {
    if (progress <= 49) return databaseAnimation; // Stage 1: Gathering Data
    if (progress <= 69) return cleaningAnimation; // Stage 2: Cleaning Data
    if (progress <= 94) return predictionAnimation; // Stage 3: Training the Model
    return neuralNetworkAnimation; // Stage 4: Evaluating the Output Signal
  };

  const createAnonymousUser = async () => {
    try {
      // Fetch client IP address from an external service
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const clientIp = ipResponse.data.ip;

      // Make a request to create an anonymous user with client IP in the headers
      const response = await axios.post(
        `${baseURL}/create-anon-user`,
        {},
        {
          headers: {
            "Client-IP": clientIp, // Add IP address to headers
          },
        }
      );

      const { user_id } = response.data;
      localStorage.setItem("anonUserId", user_id);
      setAnonUserId(user_id);
    } catch (error) {
      console.error("Error creating anonymous user:", error);
    }
  };

  const fetchCredits = async () => {
    const userId = localStorage.getItem("uid");
    const firebaseuid = localStorage.getItem("firebaseuid");
    if (!userId) {
      console.error("No user ID found in localStorage.");
      return;
    }
    if (!firebaseuid) {
      console.error("No firebase user ID found in localStorage.");
      return;
    }

    try {
      const response = await fetch(`${baseURL}/get-credits`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userId}:${firebaseuid}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch credits. Status: ${response.status}`);
      }

      const result = await response.json();
      setCredits(result.credits_available);
      localStorage.setItem("credits", result.credits_available); // Update localStorage
    } catch (error) {
      console.error("Error fetching credits:", error);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("uid");
    let pageType = pageTypeMapping[location.pathname] || activeTab || "UNK";

    // If the "pid" query parameter is present, this is a "View Analysis" action
    const params = new URLSearchParams(location.search);
    if (params.has("pid") && location.pathname === "/dashboard") {
      pageType = "VWAN"; // View Analysis
    }
    logUserActivity(userId, pageType);
  }, [location.pathname]);

  const signIn = async () => {
    setAuthLoading(true); // Set authentication loading state
    await handleGoogleSignIn(navigate, setLoading); // Handle Google sign-in

    try {
      const userId = localStorage.getItem("uid");
      const predictionId = localStorage.getItem("predictionId");

      if (!userId) {
        console.error("No user ID found in localStorage. Please sign in.");
        setAuthLoading(false);
        return;
      }

      const currentCredits = localStorage.getItem("credits");

      // Handle insufficient credits
      if (
        Number(currentCredits) <
        Number(process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE)
      ) {
        localStorage.removeItem("predictionData");
        setFlag(true);
        setPopupOpen(true); // Show upgrade popup
        setStatusMessage("Insufficient credits. Please upgrade.");
        setAuthLoading(false);
        return;
      }

      // Continue if credits are sufficient

      const updatePredictionResponse = await fetch(
        `${baseURL}/update-prediction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prediction_id: predictionId,
            user_id: userId,
          }),
        }
      );

      if (updatePredictionResponse.ok) {
        const storedPredictionData = localStorage.getItem("predictionData");
        if (storedPredictionData) {
          const parsedPredictionData = JSON.parse(storedPredictionData);
          setApiData(JSON.parse(storedPredictionData));
          const result = parsePredictionData(parsedPredictionData.prediction);
          if (result) {
            setParsedPrediction(result.parsedData);
            setConfidenceMeterMargin(result.margin * -1 - 7);
            result.accuracy !== -1
              ? setAccuracyMeterMargin(result.accuracy * -1 - 7)
              : setAccuracyMeterMargin(-7);
            setStatusMessage("Prediction data loaded successfully!");
            await fetchCredits(); // Refresh credits after prediction update
            setActiveTab("analyze");
            navigate(`/dashboard`);
          }
        } else {
          console.error("No prediction data found in localStorage.");
        }
      } else {
        throw new Error("Failed to update prediction.");
      }
    } catch (error) {
      console.error("Error during sign-in process:", error);
      setStatusMessage(`Error during sign-in: ${error.message}`);
    } finally {
      setAuthLoading(false);
    }
  };

  const getCompanyTitle = (ticker) => {
    const company = Object.values(companyTickers).find(
      (item) => item.ticker === ticker.toUpperCase()
    );
    return company ? company.title : "Company Not Found";
  };

  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      fetchCredits();
    }
    if (location.state?.showUpgradePopup) {
      setPopupOpen(true);
    }
    if (location.state?.refreshCredits) {
      fetchCredits();
    }
  }, [location.state]);

  useEffect(() => {
    if (
      !localStorage.getItem("uid") &&
      !localStorage.getItem("anonUserId") &&
      !pid
    ) {
      navigate("/"); // Redirect to login or another page
    } else {
      const storedCredits = localStorage.getItem("credits");
      if (storedCredits !== null) {
        setCredits(storedCredits);
      } else {
        setCredits(0);
      }
    }

    if (prediction && !hasFetched) {
      setApiData(prediction);
      const result = parsePredictionData(prediction.prediction);
      if (result) {
        setParsedPrediction(result.parsedData);
        setConfidenceMeterMargin(result.margin * -1 - 7);
        result.accuracy !== -1
          ? setAccuracyMeterMargin(result.accuracy * -1 - 7)
          : setAccuracyMeterMargin(-7);
        localStorage.setItem("predictionData", JSON.stringify(prediction));
        setHasFetched(true);
      }
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        //fetchCredits(); // Uncomment if you have a fetchCredits function
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [apiData, prediction, hasFetched, user, navigate, credits]);

  const handleFetchData = async (ticker) => {
    if (!ticker) {
      alert("Please enter a ticker symbol");
      return;
    }
    if (
      Number(credits) <
      Number(process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE)
    ) {
      setPopupOpen(true);
      return;
    }

    // Create a unique session key for this ticker
    const sessionKey = `predictionData_${ticker}`;

    // Remove old data for this ticker session
    localStorage.removeItem(sessionKey);

    // Clear states for a new fetch
    setApiData(null);
    setParsedPrediction(null);
    setConfidenceMeterMargin(-7);
    setAccuracyMeterMargin(-7);
    setStatusMessage("");
    setHasFetched(false);
    setFetchError(false);
    setProgressLoading(true);
    setProgress(0);
    setDataLoading(true);

    try {
      const userId = localStorage.getItem("uid") || anonUserId;
      const firebaseuid = localStorage.getItem("firebaseuid") || null;

      const authToken = firebaseuid ? `${userId}:${firebaseuid}` : userId;

      setActiveTab("analyze");
      navigate(`/dashboard`, { replace: true });

      // Start listening to progress updates from the backend
      const eventSource = new EventSource(
        `${baseURL}/get-prediction?ticker=${ticker}&auth=${authToken}`
      );

      // Track the EventSource instance for this session
      if (!eventSourceRef.current) {
        eventSourceRef.current = {};
      }
      eventSourceRef.current[sessionKey] = eventSource;

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.error) {
          console.error("Error:", data.error);
          setFetchError(true);
          setStatusMessage(`Error: ${data.error}`);
          eventSource.close();
          setProgressLoading(false);
          return;
        }

        // Update progress and status message for this session
        if (data.progress !== undefined) {
          setProgress(data.progress);
          setProgressLoading(true);
        }

        if (data.status) {
          setStatusMessage(data.status);
        }

        if (data.progress === 100) {
          setProgressLoading(false);
        }

        // Final data from the backend
        if (data.data) {
          setApiData(data.data.prediction);
          localStorage.setItem(sessionKey, JSON.stringify(data.data));
          localStorage.setItem("predictionData", JSON.stringify(data.data)); // Store prediction data uniquely
          if (data.data.prediction) {
            const result = parsePredictionData(data.data.prediction);
            if (result) {
              setParsedPrediction(result.parsedData);
              setConfidenceMeterMargin(result.margin * -1 - 7);
              result.accuracy !== -1
                ? setAccuracyMeterMargin(result.accuracy * -1 - 7)
                : setAccuracyMeterMargin(-7);
            }
          }
          setProgress(100);
          setStatusMessage("Prediction completed!");
          fetchCredits(); // Refresh credits
          eventSource.close();
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource error:", error);
        setFetchError(true);
        setStatusMessage("Error fetching data. Please try again.");
        setProgressLoading(false);
        eventSource.close();
      };
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchError(true);
      setStatusMessage(`Error: ${error.message}`);
    } finally {
      setDataLoading(false);
      setProgressLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        Object.values(eventSourceRef.current).forEach((eventSource) =>
          eventSource.close()
        );
      }
    };
  }, []);

  useEffect(() => {
    if (pid && !user && !anonUserId) {
      // If there's a pid and the user is not logged in
      createAnonymousUser(); // Fetch the prediction data
    } else if (pid) {
      handleDisplayData(pid);
    }
  }, [location, user, anonUserId]); // Re-run if location or user status changes

  useEffect(() => {
    const checkIfInFavorites = async () => {
      if (!parsedPrediction) return;
      const userId = localStorage.getItem("databaseUid");
      try {
        const response = await axios.get(
          `${baseURL}/check-in-favorites?uid=${userId}&ticker=${parsedPrediction.ticker.toUpperCase()}`
        );
        setIsInFavorites(response.data.in_favorites);
      } catch (err) {
        console.log("Error: " + err);
      }
    };
    checkIfInFavorites();
  }, [parsedPrediction]);

  const handleDisplayData = async (prediction_id) => {
    setApiData(null);
    setParsedPrediction(null);
    setConfidenceMeterMargin(-7);
    setAccuracyMeterMargin(-7);
    localStorage.removeItem("predictionData");
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("predictionData_")) {
        localStorage.removeItem(key);
      }
    });

    try {
      let userID = user
        ? localStorage.getItem("uid")
        : localStorage.getItem("anonUserId");
      let firebase_uid = user ? user.uid : " ";
      const response = await fetch(`${baseURL}/get-prediction-json`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          prediction_id: prediction_id,
          user_id: userID,
          firebase_uid: firebase_uid,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
      const result = await response.json();
      if (user) {
        // If user is logged in, display full data
        setApiData(result.prediction_json[1]);
        setParsedPrediction(result.prediction_json[1]);
        let confidence = calculateConfidence(
          Number(result.prediction_json[1].std_deviation),
          Number(
            result.prediction_json[1].next_day_change_percent.replace("%", "")
          )
        );
        const margin = 100 - confidence;
        setConfidenceMeterMargin(margin * -1 - 7);
        let accuracy = calculateAccuracy(result.prediction_json[1].accuracy);
        accuracy !== -1
          ? setAccuracyMeterMargin(accuracy * -1 - 7)
          : setAccuracyMeterMargin(-7);
        await fetchCredits();
      } else {
        // If user is anonymous, display masked data
        const maskedData = {
          ...result.prediction_json[1],
          prediction:
            "Predicted data is hidden for anonymous users. Please log in to view details.",
        };
        setApiData(maskedData);
        setParsedPrediction(maskedData);
        setConfidenceMeterMargin(-7);
      }
      setStatusMessage("Prediction data loaded successfully!");
      // Handle the response here (e.g., check response.ok and parse the JSON)
    } catch (error) {
      setFetchError(true);
      setStatusMessage(`Error fetching prediction data: ${error.message}`);
    } finally {
      setDataLoading(false);
      localStorage.removeItem("anonUserId");
    }
  };

  // Function to open the disclaimer popup
  const openDisclaimer = () => {
    setIsDisclaimerOpen(true);
    document.body.style.overflow = "hidden"; // Prevent background scrolling
  };

  // Function to close the disclaimer popup
  const closeDisclaimer = () => {
    setIsDisclaimerOpen(false);
    document.body.style.overflow = "auto"; // Re-enable background scrolling
    // Return focus to the disclaimer button
    if (disclaimerButtonRef.current) {
      disclaimerButtonRef.current.focus();
    }
  };

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape" && isDisclaimerOpen) {
        closeDisclaimer();
      }
    };

    document.addEventListener("keydown", handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isDisclaimerOpen]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      // Clear all stored data in localStorage on logout
      localStorage.removeItem("uid"); // Remove user ID from localStorage
      localStorage.removeItem("predictionData"); // Remove prediction data from localStorage
      localStorage.clear();
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleAccount = async () => {
    setActiveTab("account");
    navigate("/dashboard/account");
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const currentTab = location.pathname.split("/")[2] || "analyze";
    if (urlActiveTab === null) {
      setActiveTab(currentTab);
    }
  }, [location]);

  useEffect(() => {
    if (activeTab === "analyze") {
      // setApiData(null);
      // setParsedPrediction(null);
      setHasFetched(false);
      setFetchError(false);
      // localStorage.removeItem('predictionData');
    }
  }, [activeTab]);

  function formatNumber(num) {
    const absNum = Math.abs(Number(num));
    const sign = num < 0 ? "-" : "";
    const trillion = 1e12; // 1,000,000,000,000
    const billion = 1e9; // 1,000,000,000
    const million = 1e6; // 1,000,000

    if (absNum >= trillion) {
      return `${sign}${(absNum / trillion).toFixed(2)}T`;
    } else if (absNum >= billion) {
      return `${sign}${(absNum / billion).toFixed(2)}B`;
    } else if (absNum >= million) {
      return `${sign}${(absNum / million).toFixed(2)}M`;
    } else {
      return `${sign}${absNum.toLocaleString("en-US")}`;
    }
  }

  const addToFavorites = async () => {
    if (!parsedPrediction) return;
    const userId = localStorage.getItem("databaseUid");
    try {
      setIsInFavorites(true);
      await axios.get(
        `${baseURL}/add-to-favorites?uid=${userId}&ticker=${parsedPrediction.ticker.toUpperCase()}`
      );
    } catch (err) {
      console.log("Failed to add to favorites: " + err);
    }
  };

  const removeFromFavorites = async () => {
    if (!parsedPrediction) return;
    const userId = localStorage.getItem("databaseUid");
    try {
      setIsInFavorites(false);
      await axios.get(
        `${baseURL}/remove-from-favorites?uid=${userId}&ticker=${parsedPrediction.ticker.toUpperCase()}`
      );
    } catch (err) {
      console.log("Failed to remove from favorites: " + err);
    }
  };

  function processGptOutput(gptOutput) {
    const technicalAnalysisIndex = gptOutput.indexOf("Technical Analysis");

    if (technicalAnalysisIndex === -1) {
      console.warn("Technical Analysis section not found.");
      // Handle the absence of the section
      return {
        technicalAnalysis: "No technical analysis available.",
        // Other sections...
      };
    }

    // Existing logic to split and return sections...
  }

  function adjustLayout() {
    const menu = document.querySelector(".menu");
    const analysis = document.querySelector(".analysis");

    if (menu && analysis) {
      menu.style.marginBottom = "0";
      analysis.style.marginTop = "0";
    }
  }

  useEffect(() => {
    adjustLayout();
  }, []);

  return (
    <div className="logged-in-page">
      <Helmet>
        <title>Stocks: Advanced AI based Research and Prediction</title>
        <meta
          name="description"
          content="Use MarketCrunch's AI signals for stocks to buy"
        />
      </Helmet>
      <header className="header-dashboard">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Technical Difficulty Banner - Temporarily Hidden
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", backgroundColor: "#ffcc00", padding: 5 }}>
            <span style={{ fontWeight: 600, fontFamily: "Inter", fontSize: 14 }}>
              We are experiencing technical difficulty pulling a Prediction right now. Contact <a href="mailto:support@marketcrunch.ai">support@marketcrunch.ai</a> for any questions
            </span>
          </div>
          */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: 20,
            }}
          >
            <div className="header-left">
              {/* Hamburger Menu Icon for Mobile */}
              <button
                className="hamburger-menu"
                onClick={toggleMenu}
                aria-label="Toggle navigation menu"
              >
                {isMenuOpen ? (
                  <FaTimes color="#1D3557" />
                ) : (
                  <FaBars color="#1D3557" />
                )}
              </button>

              <h3 style={{ display: "inline-block", position: "relative" }}>
                <a
                  href="/"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  MarketCrunch AI
                  <span
                    style={{
                      fontSize: "0.75em",
                      position: "relative",
                      top: "-0.2em",
                    }}
                  >
                    &#8482;
                  </span>
                  <span
                    style={{
                      display: "block",
                      fontSize: "0.5em",
                      position: "absolute",
                      left: "75%",
                      top: "2.3em",
                      fontStyle: "italic",
                      letterSpacing: "0.05em",
                    }}
                  >
                    BETA
                  </span>
                </a>
              </h3>
            </div>

            <div className="header-right" ref={dropdownRef}>
              {user ? (
                <div className="user-dropdown logout-dropdown">
                  <div
                    className="user-icon"
                    onClick={toggleDropdown}
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen}
                  >
                    {user?.displayName?.charAt(0)}
                  </div>
                  {isDropdownOpen && (
                    <div className="dropdown-menu">
                      <button className="logout-button" onClick={handleLogout}>
                        Logout
                      </button>
                      <button className="logout-button" onClick={handleAccount}>
                        Account
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <button className="signin-button" onClick={signIn}>
                  Free Sign-up
                </button>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Sidebar Navigation - Visible on Desktop, Hidden on Mobile */}

      <div className={`sidebar-nav ${isMenuOpen ? "active" : ""}`}>
        <div className="nav-section">
          <div className="section-header">SANDBOX</div>
          <button
            onClick={() => {
              setActiveTab("analyze");
              setMenuOpen(false);
              navigate("/dashboard");
            }}
            className={activeTab === "analyze" ? "active-tab" : ""}
          >
            <FiPieChart style={{ marginRight: "8px" }} /> Analyze
          </button>

          {user && (
            <>
              <button
                onClick={() => {
                  setActiveTab("favorites");
                  setMenuOpen(false);
                  navigate("/dashboard/favorites");
                }}
                className={activeTab === "favorites" ? "active-tab" : ""}
              >
                <FiHeart style={{ marginRight: "8px" }} /> Favorites
              </button>

              <button
                onClick={() => {
                  setActiveTab("ai-picks");
                  setMenuOpen(false);
                  navigate("/dashboard/ai-picks");
                }}
                className={activeTab === "ai-picks" ? "active-tab" : ""}
              >
                <FiStar style={{ marginRight: "8px" }} /> AI Picks
              </button>

              <button
                onClick={() => {
                  setActiveTab("trending");
                  setMenuOpen(false);
                  navigate("/dashboard/trending");
                }}
                className={activeTab === "trending" ? "active-tab" : ""}
              >
                <FiTrendingUp style={{ marginRight: "8px" }} /> Trending
              </button>
            </>
          )}
        </div>

        <div className="nav-section">
          <div className="section-header">ACCOUNT</div>
          <button
            onClick={() => {
              setActiveTab("account");
              navigate("/dashboard/account");
            }}
            className={activeTab === "account" ? "active-tab" : ""}
          >
            <FiUser style={{ marginRight: "10px" }} /> Profile
          </button>
          {user && (
            <button
              onClick={() => {
                setActiveTab("history");
                setMenuOpen(false);
                navigate("/dashboard/history");
              }}
              className={activeTab === "history" ? "active-tab" : ""}
            >
              <FiClock style={{ marginRight: "8px" }} /> History
            </button>
          )}
          <button
            onClick={handleLogout}
            className={activeTab === "logout" ? "active-tab" : ""}
          >
            <FiLogOut style={{ marginRight: "10px" }} /> Logout
          </button>
        </div>
      </div>
      {/* Overlay when mobile menu is open */}
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}

      <div className="main-content">
        {user && (
          <div className="header-container">
            <span className="header-title">
              {activeTab === "screeners" ? "AI Picks" : activeTab}
            </span>
            <div className="credits-display-container">
              <i className="fas fa-wallet"></i>
              <span className="credits-info">
                {credits !== null ? credits : "Loading..."} credits
              </span>
            </div>
          </div>
        )}

        {activeTab === "analyze" && user && (
          <div className="dashboard-search-container">
            <SearchBox
              onSearch={handleFetchData}
              loading={loading}
              buttonText="Analyze"
              isLanding={false}
            />
            {/* {user && ( */}
            <div className="dashboard-disclaimer">
              <br />
              <p style={{ fontFamily: "'Merriweather', serif" }}>
                {" "}
                Run an analysis or see what's{" "}
                <Link
                  to="/dashboard/trending"
                  onClick={() => setActiveTab("trending")}
                  style={{ color: "#1D3557", textDecoration: "none" }}
                >
                  TRENDING
                </Link>
              </p>
            </div>
            {/* )} */}

            {/* <div className="filters">
              <label>
                <input type="checkbox" /> Technicals
              </label>
              <label>
                <input type="checkbox" /> Fundamentals
              </label>
              <label className="tooltip">
                <input type="checkbox" disabled /> Macro
                <span className="tooltip-text">Coming soon!</span>
              </label>
              <br/>
            </div> */}
            {/* Welcome Message */}
            {!parsedPrediction && !dataLoading && !progressLoading && (
              <div className="welcome-message">
                <p>
                  Our advanced AI analyzes complex data from fundamentals,
                  technicals, and other sources to give you real, detailed, and
                  impactful insights.
                </p>
                <p>No hallucinations.</p>
              </div>
            )}
          </div>
        )}

        {activeTab === "analyze" && (
          <div>
            {progressLoading && (
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}>
                  <span className="progress-text">{progress}%</span>
                </div>
                {/* <p>{statusMessage}<br /></p> */}
                <div className="animation-container-dashboard">
                  {progress > 49 && progress <= 69 && (
                    <img
                      src={require("../assets/images/reddit.png")}
                      alt="Reddit Logo"
                      className="stage-image-dashboard"
                    />
                  )}
                  <Lottie
                    animationData={getAnimationForStage()}
                    loop={true}
                    style={{ height: 300 }}
                  />
                </div>
              </div>
            )}
            <div className="blur-wrapper">
              {!user && !authLoading && (
                <div className="overlay-content">
                  <p className="sign-up-text">
                    Our deep-learning model analyzed{" "}
                    {parsedPrediction?.data_points ? (
                      <strong>{parsedPrediction.data_points}</strong>
                    ) : (
                      "thousands of"
                    )}{" "}
                    data points since{" "}
                    {parsedPrediction?.first_trade_date ? (
                      <strong>{parsedPrediction.first_trade_date}</strong>
                    ) : (
                      "inception"
                    )}{" "}
                    for{" "}
                    {parsedPrediction?.ticker ? (
                      <strong>{parsedPrediction.ticker}</strong>
                    ) : (
                      "the ticker"
                    )}
                    .<br />
                    <br />
                    Your information stays private.
                    <br />
                  </p>
                  <button className="sign-in-button" onClick={signIn}>
                    <img
                      src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                      alt="Google Icon"
                      className="google-icon"
                    />
                    Sign-up for Free
                  </button>
                  <p className="sign-up-text-ps">
                    By creating an account, you agree to MarketCrunchAI's{" "}
                    <b>Privacy Policy</b> and <b>Terms of Service</b> (see
                    footer).
                  </p>
                </div>
              )}
              {parsedPrediction && !dataLoading && !progressLoading && (
                <div
                  className={`prediction-data ${!user ? "blurred" : ""} && ${
                    !Flag ? "" : "blurred"
                  }`}
                >
                  <br />
                  <div className="reddit-share-container">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span>Share:</span>
                      <span
                        className="copy-link-icon"
                        onClick={handleCopyLink}
                        onMouseEnter={(e) => {
                          const tooltipshare = document.createElement("div");
                          tooltipshare.textContent = "Copy Link";
                          tooltipshare.className = "tooltip";
                          tooltipshare.style.position = "absolute";
                          tooltipshare.style.backgroundColor = "#333";
                          tooltipshare.style.color = "#fff";
                          tooltipshare.style.padding = "4px 8px";
                          tooltipshare.style.borderRadius = "4px";
                          tooltipshare.style.fontSize = "12px";
                          tooltipshare.style.boxShadow =
                            "0 2px 4px rgba(0, 0, 0, 0.2)";
                          tooltipshare.style.whiteSpace = "nowrap";
                          tooltipshare.style.pointerEvents = "none";
                          tooltipshare.style.zIndex = "1000";
                          tooltipshare.style.top = `${e.clientY - 60}px`;
                          tooltipshare.style.left = `${e.clientX - 45}px`;
                          document.body.appendChild(tooltipshare);
                          e.currentTarget.tooltipshare = tooltipshare;
                        }}
                        onMouseLeave={(e) => {
                          if (e.currentTarget.tooltipshare) {
                            document.body.removeChild(
                              e.currentTarget.tooltipshare
                            );
                            e.currentTarget.tooltipshare = null;
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          margin: "0 8px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <FiLink size={24} />
                      </span>

                      <RedditShareButton
                        onMouseEnter={(e) => {
                          const tooltipshare = document.createElement("div");
                          tooltipshare.textContent = "Share to Reddit";
                          tooltipshare.className = "tooltip";
                          tooltipshare.style.position = "absolute";
                          tooltipshare.style.backgroundColor = "#333";
                          tooltipshare.style.color = "#fff";
                          tooltipshare.style.padding = "4px 8px";
                          tooltipshare.style.borderRadius = "4px";
                          tooltipshare.style.fontSize = "12px";
                          tooltipshare.style.boxShadow =
                            "0 2px 4px rgba(0, 0, 0, 0.2)";
                          tooltipshare.style.whiteSpace = "nowrap";
                          tooltipshare.style.pointerEvents = "none";
                          tooltipshare.style.zIndex = "1000";
                          tooltipshare.style.top = `${e.clientY - 60}px`;
                          tooltipshare.style.left = `${e.clientX - 50}px`;
                          document.body.appendChild(tooltipshare);
                          e.currentTarget.tooltipshare = tooltipshare;
                        }}
                        onMouseLeave={(e) => {
                          if (e.currentTarget.tooltipshare) {
                            document.body.removeChild(
                              e.currentTarget.tooltipshare
                            );
                            e.currentTarget.tooltipshare = null;
                          }
                        }}
                        url={`https://marketcrunch.ai/dashboard?pid=${(() => {
                          const predictionData =
                            localStorage.getItem("predictionData");
                          if (predictionData) {
                            try {
                              return (
                                JSON.parse(predictionData)?.prediction_id ||
                                "unknown"
                              );
                            } catch (error) {
                              console.error(
                                "Error parsing predictionData from localStorage:",
                                error
                              );
                              return "unknown";
                            }
                          } else {
                            // Use the current page URL as a fallback
                            const currentUrl = window.location.href;
                            return (
                              new URL(currentUrl).searchParams.get("pid") ||
                              "unknown"
                            );
                          }
                        })()}`}
                        title={`${parsedPrediction.ticker} | MarketCrunch's deep-learning model analyzed ${parsedPrediction.data_points} trading data points since ${parsedPrediction.first_trade_date}. See price prediction:`}
                        className="reddit-share-button"
                        onClick={() => {
                          // Log the activity for page mapping as SH_R
                          const userId =
                            localStorage.getItem("uid") || anonUserId;
                          logUserActivity(userId, "SH_R");
                        }}
                      >
                        <RedditIcon size={32} round={true} />
                      </RedditShareButton>
                      <TwitterShareButton
                        onMouseEnter={(e) => {
                          const tooltipshare = document.createElement("div");
                          tooltipshare.textContent = "Share to Twitter";
                          tooltipshare.className = "tooltip";
                          tooltipshare.style.position = "absolute";
                          tooltipshare.style.backgroundColor = "#333";
                          tooltipshare.style.color = "#fff";
                          tooltipshare.style.padding = "4px 8px";
                          tooltipshare.style.borderRadius = "4px";
                          tooltipshare.style.fontSize = "12px";
                          tooltipshare.style.boxShadow =
                            "0 2px 4px rgba(0, 0, 0, 0.2)";
                          tooltipshare.style.whiteSpace = "nowrap";
                          tooltipshare.style.pointerEvents = "none";
                          tooltipshare.style.zIndex = "1000";
                          tooltipshare.style.top = `${e.clientY - 60}px`;
                          tooltipshare.style.left = `${e.clientX - 80}px`;
                          document.body.appendChild(tooltipshare);
                          e.currentTarget.tooltipshare = tooltipshare;
                        }}
                        onMouseLeave={(e) => {
                          if (e.currentTarget.tooltipshare) {
                            document.body.removeChild(
                              e.currentTarget.tooltipshare
                            );
                            e.currentTarget.tooltipshare = null;
                          }
                        }}
                        style={{ marginLeft: 10 }}
                        url={`https://marketcrunch.ai/dashboard?pid=${(() => {
                          const predictionData =
                            localStorage.getItem("predictionData");
                          if (predictionData) {
                            try {
                              return (
                                JSON.parse(predictionData)?.prediction_id ||
                                "unknown"
                              );
                            } catch (error) {
                              console.error(
                                "Error parsing predictionData from localStorage:",
                                error
                              );
                              return "unknown";
                            }
                          } else {
                            // Use the current page URL as a fallback
                            const currentUrl = window.location.href;
                            return (
                              new URL(currentUrl).searchParams.get("pid") ||
                              "unknown"
                            );
                          }
                        })()}`}
                        title={`${parsedPrediction.ticker} | MarketCrunch's deep-learning model analyzed ${parsedPrediction.data_points} trading data points since ${parsedPrediction.first_trade_date}. See price prediction:`}
                        onClick={() => {
                          // Log the activity for page mapping as SH_R
                          const userId =
                            localStorage.getItem("uid") || anonUserId;
                          logUserActivity(userId, "SH_X");
                        }}
                      >
                        <XIcon size={32} round={true} />
                      </TwitterShareButton>
                    </div>
                  </div>

                  <div className="stock-container">
                    <h2
                      style={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {`${
                        parsedPrediction.company_info.longName
                      } (${parsedPrediction.ticker.toUpperCase()})`}
                      {parsedPrediction.ticker && (
                        <button
                          onClick={
                            isInFavorites ? removeFromFavorites : addToFavorites
                          }
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            marginLeft: "5px",
                          }}
                          aria-label={
                            isInFavorites
                              ? "Remove from favorites"
                              : "Add to favorites"
                          }
                        >
                          {isInFavorites ? (
                            <FaHeart color="#FF4C61" size={20} />
                          ) : (
                            <FaHeart
                              size={20}
                              style={{ color: "black", fill: "white" }}
                            />
                          )}
                        </button>
                      )}
                    </h2>
                    <div className="title-container">
                      <p>
                        <span
                          className={
                            parseFloat(
                              parsedPrediction.next_day_change_percent
                            ) > 0
                              ? "positive-change"
                              : "negative-change"
                          }
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            marginRight: "8px",
                          }}
                        >
                          {parseFloat(
                            parsedPrediction.next_day_change_percent
                          ) > 0
                            ? "▲"
                            : "▼"}
                        </span>
                        <span
                          style={{ filter: !user ? "blur(5px)" : "blur(0px)" }}
                        >
                          Prediction {parsedPrediction.price_target}{" "}
                        </span>
                        <span
                          className={
                            parseFloat(
                              parsedPrediction.next_day_change_percent
                            ) > 0
                              ? "positive-change"
                              : "negative-change"
                          }
                          style={{ filter: !user ? "blur(5px)" : "blur(0px)" }}
                        >
                          ({parsedPrediction.next_day_change_percent})
                        </span>{" "}
                        for {parsedPrediction.pred_date}. <br />
                      </p>
                      {(parsedPrediction.confidence_level ||
                        parsedPrediction.news_sentiment_label ||
                        (parsedPrediction.accuracy !== undefined &&
                          parsedPrediction.accuracy.toString() !== "-1")) && (
                        <div
                          style={{
                            opacity: 0.65,
                            fontSize: 15,
                            marginTop: 15,
                            display: "flex",
                            flexDirection: width < 485 ? "column" : "row",
                          }}
                        >
                          {/* Mobile View first, then Desktop View */}
                          {width < 485 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10,
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "end",
                                  gap: 5,
                                }}
                              >
                                {accuracyMeterMargin !== -7 ? (
                                  <span>Accuracy: </span>
                                ) : (
                                  <></>
                                )}
                                <span
                                  style={{
                                    color: "rgba(0, 0, 0, 1)",
                                    fontWeight: 600,
                                  }}
                                >
                                  Confidence Level:{" "}
                                </span>
                                <span
                                  style={{
                                    color: "rgba(0, 0, 0, 1)",
                                    fontWeight: 600,
                                  }}
                                >
                                  News Sentiment:{" "}
                                </span>
                                <span className="company-info-stat-title">
                                  Dividend Yield:
                                </span>
                                <span className="company-info-stat-title">
                                  Market Cap:
                                </span>
                                <span className="company-info-stat-title">
                                  52-Week High:
                                </span>
                                <span className="company-info-stat-title">
                                  52-Week Low:
                                </span>
                                <span className="company-info-stat-title">
                                  Forward P/E:
                                </span>
                                <span className="company-info-stat-title">
                                  Trailing P/E:
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  gap: 5,
                                }}
                              >
                                {accuracyMeterMargin !== -7 ? (
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      marginTop: 5,
                                    }}
                                  >
                                    <div className="confidence-level"></div>
                                    <span
                                      className="confidence-triangle"
                                      style={{
                                        width: 0,
                                        display: "inline-flex",
                                        position: "relative",
                                        left: `${accuracyMeterMargin}px`,
                                      }}
                                    >
                                      ▲
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div
                                  style={{
                                    display: "inline-flex",
                                    marginTop:
                                      accuracyMeterMargin !== -7 ? 0 : 5,
                                  }}
                                >
                                  <div className="confidence-level"></div>
                                  <span
                                    className="confidence-triangle"
                                    style={{
                                      width: 0,
                                      display: "inline-flex",
                                      position: "relative",
                                      left: `${confidenceMeterMargin}px`,
                                    }}
                                  >
                                    ▲
                                  </span>
                                </div>
                                <span
                                  style={{
                                    marginTop:
                                      accuracyMeterMargin !== -7 ? -5 : -4,
                                    fontSize: 14,
                                  }}
                                >
                                  {parsedPrediction.news_sentiment_label}
                                </span>
                                <span className="company-info-stat-value">
                                  {parsedPrediction.company_info.dividendYield}
                                </span>
                                <span className="company-info-stat-value">
                                  {formatNumber(
                                    parsedPrediction.company_info.marketCap
                                  )}
                                </span>
                                <span className="company-info-stat-value">
                                  $
                                  {Number(
                                    parsedPrediction.company_info
                                      .fiftyTwoWeekHigh
                                  ).toFixed(2)}
                                </span>
                                <span className="company-info-stat-value">
                                  $
                                  {Number(
                                    parsedPrediction.company_info
                                      .fiftyTwoWeekLow
                                  ).toFixed(2)}
                                </span>
                                <span className="company-info-stat-value">
                                  {Number(
                                    parsedPrediction.company_info.forwardPE
                                  ).toFixed(1)}
                                </span>
                                <span className="company-info-stat-value">
                                  {Number(
                                    parsedPrediction.company_info.trailingPE
                                  ).toFixed(1)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <>
                                {accuracyMeterMargin !== -7 && (
                                  <>
                                    <div>
                                      <span
                                        style={{
                                          marginRight: 5,
                                          color: "rgba(0, 0, 0, 1)",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Accuracy:
                                      </span>
                                      <div style={{ display: "inline-flex" }}>
                                        <div className="confidence-level"></div>
                                        <span
                                          className="confidence-triangle"
                                          style={{
                                            width: 0,
                                            display: "inline-flex",
                                            position: "relative",
                                            left: `${accuracyMeterMargin}px`,
                                          }}
                                        >
                                          ▲
                                        </span>
                                      </div>
                                    </div>
                                    {accuracyMeterMargin !== -7 ? (
                                      <span style={{ margin: "0 5px" }}>
                                        {" "}
                                        |{" "}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                                {parsedPrediction.confidence_level && (
                                  <>
                                    <div>
                                      <span
                                        style={{
                                          marginRight: 5,
                                          color: "rgba(0, 0, 0, 1)",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Confidence Level:
                                      </span>
                                      <div style={{ display: "inline-flex" }}>
                                        <div className="confidence-level"></div>
                                        <span
                                          className="confidence-triangle"
                                          style={{
                                            width: 0,
                                            display: "inline-flex",
                                            position: "relative",
                                            left: `${confidenceMeterMargin}px`,
                                          }}
                                        >
                                          ▲
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {parsedPrediction.confidence_level &&
                                parsedPrediction.news_sentiment_label &&
                                width < 485 ? (
                                  <></>
                                ) : (
                                  <span style={{ margin: "0 5px 0 7px" }}>
                                    {" "}
                                    |{" "}
                                  </span>
                                )}
                                {parsedPrediction.news_sentiment_label && (
                                  <>
                                    <div>
                                      <span
                                        style={{
                                          color: "rgba(0, 0, 0, 1)",
                                          fontWeight: 600,
                                        }}
                                      >
                                        News Sentiment:{" "}
                                      </span>
                                      <span
                                        style={{ color: "rgba(0, 0, 0, 1)" }}
                                      >
                                        {parsedPrediction.news_sentiment_label}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    {/*   UNHIDE this comment when the API is working.
                      <div className="stock-grid"> 
                        <div className="side-by-side">
                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.day_change.toFixed(2)}%</span>
                            <p>{apiData?.dayBeforeFormatted}</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.week_change.toFixed(2)}%</span>
                            <p>{apiData?.weekBeforeFormatted}</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.month_change.toFixed(2)}%</span>
                            <p>Last 1 mo</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.quarter_change.toFixed(2)}%</span>
                            <p>Last 3 mo.</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.year_change.toFixed(2)}%</span>
                            <p>Last 1yr</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.ytd_change.toFixed(2)}%</span>
                            <p>YTD</p>
                          </div>
                        </div>
                      </div>
                    */}
                  </div>
                  <div className="prediction-graph">
                    <PredictionOutput
                      gptOutput={parsedPrediction?.gpt_output}
                      featureImportance={parsedPrediction?.feature_importance}
                      positiveFeatures={parsedPrediction?.positive_features}
                      negativeFeatures={parsedPrediction?.negative_features}
                      apiData={parsedPrediction}
                      ticker={parsedPrediction?.ticker}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === "history" && (
          <HistoryPage
            updateActiveTab={setActiveTab}
            handleDisplayData={handleDisplayData}
          />
        )}

        {activeTab === "trending" && (
          <TrendingPage
            updateActiveTab={setActiveTab}
            handleDisplayData={handleDisplayData}
          />
        )}

        {/* {activeTab === 'screeners' && <ScreenersPage updateActiveTab={setActiveTab} />} */}

        {activeTab === "account" && <AccountPage setCredits={setCredits} />}
        <Outlet />
        {/* {activeTab === 'top-predictions' && <TopPredictionsPage />} */}
      </div>

      <UpgradePopup
        isOpen={isPopupOpen}
        handleClose={() => setPopupOpen(false)}
      />

      <Footer />
    </div>
  );
};

export default Dashboard;
