import React, { useRef, useMemo, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";

function RibbonWave({ speed, amplitude, frequency, offset, pointCount, height, width }) {
  const pointsRef = useRef();

  const grid = useMemo(() => {
    const points = [];
    for (let i = 0; i < pointCount; i++) {
      const x = (i / pointCount) * width - width / 2; // Spread points evenly across width
      const y = Math.sin((i / pointCount) * Math.PI * 2 * 3) * height; // Create wave-like effect
      const z = 0; // Default Z, updated later in animation
      points.push(x, y, z);
    }
    return new Float32Array(points);
  }, [pointCount, width, height]);

  const colors = useMemo(() => {
    const colors = [];
    for (let i = 0; i < grid.length / 3; i++) {
      const t = i / (grid.length / 3); // Transition factor for gradient
      const hue = t; // Hue changes from 0 to 1 across the points to make a rainbow
      const color = new THREE.Color().setHSL(hue, 1, 0.5); // Full saturation and lightness to get the rainbow
      colors.push(color.r, color.g, color.b);
    }
    return new Float32Array(colors);
  }, [grid]);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    const positions = pointsRef.current.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
      const angle = (i / 3) * frequency + time * speed;
      positions[i + 2] = Math.sin(angle + offset) * amplitude; // Adjust Z for wave
    }
    pointsRef.current.attributes.position.needsUpdate = true;
  });

  return (
    <points>
      <bufferGeometry ref={pointsRef}>
        <bufferAttribute
          attach="attributes-position"
          array={grid}
          count={grid.length / 3}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          array={colors}
          count={grid.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.25} // Increased size for better visibility
        vertexColors
        sizeAttenuation
        transparent
        depthWrite={false}
      />
    </points>
  );
}

export default function WaveScene() {
  const [width, setWidth] = useState(window.innerWidth >= 915 ? window.innerWidth * 0.35 : window.innerWidth * 0.45); // Scale width based on screen width

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth >= 915 ? window.innerWidth * 0.35 : window.innerWidth * 0.45); // Adjust width on resize
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ width: "100vw", height: "calc(100vh - 80px)", backgroundColor: "white", position: "absolute", zIndex: 0 }}>
      <Canvas camera={{ position: [25, 20, 40], fov: 35 }}>
        <color attach="background" args={["white"]} />
        <RibbonWave
          speed={1}
          amplitude={2}
          frequency={100}
          offset={Math.PI / 4}
          pointCount={2000}
          height={1.4}
          width={width * 0.25}
        />
        <RibbonWave
          speed={1}
          amplitude={1}
          frequency={5}
          offset={Math.PI}
          pointCount={2000}
          height={5}
          width={width * 0.35}
        />
        <RibbonWave
          speed={0.9}
          amplitude={3}
          frequency={9}
          offset={Math.PI / 2}
          pointCount={2000}
          height={2}
          width={width * 0.45}
        />
        <RibbonWave
          speed={2}
          amplitude={2}
          frequency={10}
          offset={0}
          pointCount={2000}
          height={7}
          width={width * 0.55}
        />
      </Canvas>
    </div>
  );
}
