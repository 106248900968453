import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ProgressPage.css";
import Lottie from "lottie-react";
import databaseAnimation from "../assets/animations/prediction.json";
import cleaningAnimation from "../assets/animations/reddit_comment.json";
import predictionAnimation from "../assets/animations/neural_network.json";
import neuralNetworkAnimation from "../assets/animations/bulb.json";

const ProgressPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlTicker = params.get("t");
  const navigate = useNavigate();
  const { ticker } = location.state || {};
  const [progress, setProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [fetchingError, setFetchingError] = useState(false);
  const [predictionData, setPredictionData] = useState(null);
  const [anonUserCreated, setAnonUserCreated] = useState(false);
  const [anonUserId, setAnonUserId] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const requestMade = useRef(false);
  const eventSourceRef = useRef(null);

  const getAnimationForStage = () => {
    if (progress <= 49) return databaseAnimation; // Stage 1: Gathering Data
    if (progress <= 69) return cleaningAnimation; // Stage 2: Cleaning Data
    if (progress <= 94) return predictionAnimation; // Stage 3: Training the Model
    return neuralNetworkAnimation; // Stage 4: Evaluating the Output Signal
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        let userId =
          localStorage.getItem("uid") || localStorage.getItem("anonUserId");

        if (!userId && !anonUserCreated) {
          await createAnonymousUser();
          userId = localStorage.getItem("anonUserId");

          if (!userId) {
            throw new Error("Failed to retrieve anonUserId after creating it");
          }

          setAnonUserId(userId);
        } else {
          setAnonUserId(userId);
          setIsLoggedIn(!!localStorage.getItem("uid"));
        }
        // Redirect logged-in users to the dashboard
        if (localStorage.getItem("uid")) {
          // Check credits
          const currentCredits = await fetchCredits();

          if (
            currentCredits < process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE
          ) {
            // Navigate to the dashboard and show the popup if credits are insufficient
            navigate("/dashboard", { state: { showUpgradePopup: true } });
            return;
          }
        }
        if (ticker && !requestMade.current) {
          fetchPredictionData(ticker, userId);
        } else if (urlTicker && !requestMade.current) {
          fetchPredictionData(urlTicker, userId);
        } else {
          alert("No ticker or prediction ID found");
          navigate("/");
        }
      } catch (error) {
        console.error("Initialization error:", error);
        setFetchingError(true);
      }
    };
    initialize();

    // Cleanup SSE connection on unmount
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [urlTicker, ticker, navigate]);

  const createAnonymousUser = async () => {
    try {
      const baseURL =
        process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
          ? "http://127.0.0.1:5000"
          : "https://flask-backend-52245432644.us-central1.run.app";

      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const clientIp = ipResponse.data.ip;
      const response = await axios.post(
        `${baseURL}/create-anon-user`,
        {},
        {
          headers: {
            "Client-IP": clientIp,
          },
        }
      );
      const { user_id } = response.data;
      localStorage.setItem("anonUserId", user_id);
      setAnonUserId(user_id);
      setAnonUserCreated(true);
    } catch (error) {
      console.error("Error creating anonymous user:", error);
      throw new Error("Anonymous user creation failed");
    }
  };

  const fetchCredits = async () => {
    const userId = localStorage.getItem("uid");
    const firebaseuid = localStorage.getItem("firebaseUid");
    const baseURL =
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? "http://127.0.0.1:5000"
        : "https://flask-backend-52245432644.us-central1.run.app";

    try {
      const response = await fetch(`${baseURL}/get-credits`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userId}:${firebaseuid}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch credits. Status: ${response.status}`);
      }

      const result = await response.json();
      localStorage.setItem("credits", result.credits_available); // Store updated credits in localStorage
      return result.credits_available;
    } catch (error) {
      console.error("Error fetching credits:", error);
      return 0; // Default to 0 credits if there's an error
    }
  };

  const fetchPredictionData = (ticker, userId) => {
    if (requestMade.current) return;
    requestMade.current = true;
    setRequestInProgress(true);
    // userId = localStorage.getItem('uid') || UserId;
    const firebaseuid = localStorage.getItem("firebaseuid") || null;

    const baseURL =
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? "http://127.0.0.1:5000"
        : "https://flask-backend-52245432644.us-central1.run.app";

    const authToken = firebaseuid ? `${userId}:${firebaseuid}` : userId;

    // Start listening to progress updates from the backend
    const eventSource = new EventSource(
      `${baseURL}/get-prediction?ticker=${ticker}&auth=${authToken}`
    );
    eventSourceRef.current = eventSource;

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.error) {
          console.error("Server error:", data.error);
          setFetchingError(true);
          eventSource.close();
          setRequestInProgress(false);
          requestMade.current = false;
          return;
        }

        if (data.progress !== undefined) {
          setProgress(data.progress);
          if (data.status) {
            setStatusMessage(data.status);
          }
        }

        if (data.data) {
          // Process final data
          const { prediction_id } = data.data;
          localStorage.setItem("predictionId", prediction_id);

          // Set timestamps
          const currentDate = new Date();
          const options = {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "America/New_York",
          };

          const dayBefore = new Date(currentDate);
          dayBefore.setDate(currentDate.getDate() - 1);
          const dayBeforeAnon =
            dayBefore.toLocaleDateString("en-US", options) + " ET";

          const weekBefore = new Date(currentDate);
          weekBefore.setDate(currentDate.getDate() - 7);
          const weekBeforeAnon =
            weekBefore.toLocaleDateString("en-US", options) + " ET";

          localStorage.setItem("dayBeforeAnon", dayBeforeAnon);
          localStorage.setItem("weekBeforeAnon", weekBeforeAnon);

          setPredictionData(data.data);
          setProgress(100);
          fetchCredits();

          // Navigate to dashboard
          navigate("/dashboard", {
            state: { prediction: data.data, refreshCredits: true },
          });

          eventSource.close();
        }
      } catch (error) {
        console.error("Error processing SSE message:", error);
        setFetchingError(true);
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      setFetchingError(true);
      eventSource.close();
      setRequestInProgress(false);
      requestMade.current = false;
    };
  };

  return (
    <div className="progress-page">
      <h1>
        MarketCrunch AI&#8482; <br />
        <br />
        Our AI-Engine is analyzing{" "}
        {ticker ? ticker.toUpperCase() : urlTicker.toUpperCase()}.
      </h1>
      <h2>{statusMessage}</h2>
      {/* {isLoggedIn ? (
       <p><br/>You are logged in.</p>
      ) : (
        <p><br/>Building your AI model</p>
      )}  */}
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}>
          <span className="progress-text">{progress}%</span>
        </div>
      </div>
      {/* Lottie Animation */}
      <div className="animation-container">
        {progress > 49 && progress <= 69 && (
          <img
            src={require("../assets/images/reddit.png")}
            alt="Reddit Logo"
            className="stage-image"
          />
        )}
        <Lottie
          animationData={getAnimationForStage()}
          loop={true}
          style={{ height: 300 }}
        />
      </div>

      {fetchingError && (
        <div className="error-message">
          <p>We encountered an issue. Retrying...</p>
        </div>
      )}
      {/* 
      {isLoggedIn ? (
       <p><br/>You are logged in.</p>
      ) : (
        <p><br/>Building your AI model</p>
      )} */}
    </div>
  );
};

export default ProgressPage;
