// Footer.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import Disclaimer from './Disclaimer';
import Terms from './Terms';
import Privacy from './Privacy'; // Import the Privacy component

const Footer = () => {
  const [user, setUser] = useState(null); // Store current user
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // Popup visibility state
  const [isTermsOpen, setIsTermsOpen] = useState(false); // Popup visibility state
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false); // Popup visibility state
  const navigate = useNavigate();
  const disclaimerLinkRef = useRef(null);
  const termsLinkRef = useRef(null);
  const privacyLinkRef = useRef(null);

  const handleRedirect = () => {
    user ? navigate('/dashboard') : navigate('/login');
  };

  // Check if user is logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Function to open the disclaimer popup
  const openDisclaimer = () => {
    setIsDisclaimerOpen(true);
  };

  // Function to close the disclaimer popup
  const closeDisclaimer = () => {
    setIsDisclaimerOpen(false);
    // Return focus to the disclaimer link
    if (disclaimerLinkRef.current) {
      disclaimerLinkRef.current.focus();
    }
  };

  // Function to open the terms popup
  const openTerms = () => {
    setIsTermsOpen(true);
  };

  // Function to close the terms popup
  const closeTerms = () => {
    setIsTermsOpen(false);
    // Return focus to the terms link
    if (termsLinkRef.current) {
      termsLinkRef.current.focus();
    }
  };

  // Function to open the privacy popup
  const openPrivacy = () => {
    setIsPrivacyOpen(true);
  };

  // Function to close the privacy popup
  const closePrivacy = () => {
    setIsPrivacyOpen(false);
    // Return focus to the privacy link
    if (privacyLinkRef.current) {
      privacyLinkRef.current.focus();
    }
  };

  return (
    <footer className="footer-section">
      {/* Top Row */}
      <div className="footer-top">
        {/* Adjusted this section */}
        <div className="footer-logo-signup">
          <div className="footer-logo">
            <h3>MarketCrunch AI &#8482;</h3>
          </div>
          <div className="footer-call-to-action">
            <button className="footer-signup" onClick={handleRedirect}>
              {user ? 'Dashboard' : 'Free Sign Up'}
            </button>
          </div>
        </div>
        {/* ... existing columns remain unchanged ...
        <div className="footer-column"></div>
        <div className="footer-column"></div> */}
      </div>

      {/* Separator Line */}
      <hr className="footer-separator" />

      {/* Bottom Row */}
      <div className="footer-bottom">
        {/* Left Column - Customer Service */}
        <div className="footer-column">
          <h3>
            Feedback:&ensp;<a className="email-ids" href="mailto:support@marketcrunch.ai">support@marketcrunch.ai</a>
          </h3>
          <h3>
            Sales:&ensp;<a className="email-ids" href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a>
          </h3>
          <h3 id='faq-page'>
            <span className="email"></span>
            <a className="email-ids" href="/faqs">FAQs</a>
          </h3>
        </div>

        {/* Right Column - Other Links */}
        <div className="footer-column">
          <h3>Helpful Links</h3>
          <ul>
            <li>
              <a href="https://www.finra.org/investors/investing/investing-basics" target="_blank" rel="noopener noreferrer">
                FINRA - Investing Basics
              </a>
            </li>
            <li>
              <a href="https://www.investor.gov/introduction-investing" target="_blank" rel="noopener noreferrer">
                Investor.gov (SEC) Intro to Investing
              </a>
            </li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div className="footer-social">
          <a href="https://www.instagram.com/marketcrunch.ai/" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.tiktok.com/@marketcrunch.ai" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-tiktok"></i>
          </a>
          <a href="https://www.reddit.com/user/MarketCrunchAI/" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-reddit"></i>
          </a>
          <a href="https://x.com/MarketCrunchAI" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
      <br />
      <br />

      {/* Disclaimer, Terms, and Privacy Links */}
      <div className="footer-links-row">
        <a
          href="#"
          className="footer-link"
          id="disclaimer"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            openDisclaimer();
          }}
          ref={disclaimerLinkRef}
        >
          Disclaimer
        </a>
        <span className="footer-link-separator">|</span>
        <a
          href="#"
          className="footer-link"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            openTerms();
          }}
          ref={termsLinkRef}
        >
          Terms
        </a>
        <span className="footer-link-separator">|</span>
        <a
          href="#"
          className="footer-link"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            openPrivacy();
          }}
          ref={privacyLinkRef}
        >
          Privacy
        </a>
      </div>

      {/* Include the Disclaimer component */}
      <Disclaimer
        isOpen={isDisclaimerOpen}
        onClose={closeDisclaimer}
        disclaimerButtonRef={disclaimerLinkRef}
      />

      {/* Include the Terms component */}
      <Terms
        isOpen={isTermsOpen}
        onClose={closeTerms}
        termsButtonRef={termsLinkRef}
      />

      {/* Include the Privacy component */}
      <Privacy
        isOpen={isPrivacyOpen}
        onClose={closePrivacy}
        privacyButtonRef={privacyLinkRef}
      />
    </footer>
  );
};

export default Footer;
