import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./HistoryPage.css";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import tickerData from "../company_tickers.json";
import { Helmet } from 'react-helmet';

const HistoryPage = ({ updateActiveTab, handleDisplayData }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("latest");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Number of items per page
  const navigate = useNavigate();

  const tickerLookup = Object.values(tickerData).reduce((acc, item) => {
    acc[item.ticker] = item.title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return acc;
  }, {});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const userId = localStorage.getItem("databaseUid");
    if (userId) {
      fetchData(userId);
    } else {
      setError("User is not logged in");
      setLoading(false);
    }
  }, []);

  const sortData = (dataToSort) => {
    return [...dataToSort].sort((a, b) => {
      // Convert date strings to Date objects
      const dateA = new Date(a.research_date);
      const dateB = new Date(b.research_date);
      return sortOrder === "latest" ? dateB - dateA : dateA - dateB;
    });
  };

  const fetchData = async (userId) => {
    try {
      const baseURL =
        process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
          ? "http://127.0.0.1:5000"
          : "https://flask-backend-52245432644.us-central1.run.app";

      const response = await axios.get(`${baseURL}/history`, {
        params: { user_id: userId },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const fetchedData = response.data;

      if (fetchedData && Array.isArray(fetchedData.history)) {
        setData(fetchedData.history);
        const sortedData = sortData(fetchedData.history);
        setFilteredData(sortedData);
      } else {
        setData([]);
        setFilteredData([]);
        setError("Invalid data format received");
      }

      setLoading(false);
    } catch (err) {
      setError("Failed to fetch history data. Please try again.");
      setLoading(false);
    }
  };

  // Handle search term change and sorting
  useEffect(() => {
    let results = data;

    // Apply search filter if there's a search term
    if (searchTerm) {
      results = results.filter((entry) =>
        entry.ticker.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply sorting
    const sortedResults = sortData(results);
    setFilteredData(sortedResults);
  }, [searchTerm, data, sortOrder]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleViewPrediction = async (prediction_id) => {
    updateActiveTab("analyze");
    navigate(`/dashboard?pid=${prediction_id}`);
  };

  return (
    <div className="history-page-container">
      <Helmet>
        <title>History - MarketCrunch AI</title>
        <meta name="description" content="Review your past stock market predictions with MarketCrunch AI." />
      </Helmet>
      <div className="history-container">
        <div className="header-row">
          <div className="right-side-controls">
            <input
              type="text"
              className="search-bar"
              placeholder="Search by ticker symbol..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <select
              className="sort-dropdown"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="latest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>

        <div className="history-table-container">
          <table className="history-table">
            <thead>
              <tr>
                <th>Research Date</th>
                <th>Company</th>
                <th>Ticker</th>
                <th>Prediction Date</th>
                <th>Prediction Value</th>
                <th>Prediction Type</th>
                <th>AI Analysis</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    Loading data...
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    {error}
                  </td>
                </tr>
              ) : Array.isArray(filteredData) && filteredData.length === 0 ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No history found
                  </td>
                </tr>
              ) : (
                Array.isArray(filteredData) &&
                filteredData.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.research_date}</td>
                    <td>
                      {tickerLookup[entry.ticker]
                        ? tickerLookup[entry.ticker]
                        : entry.ticker.toUpperCase()}
                    </td>
                    <td>{entry.ticker.toUpperCase()}</td>
                    <td>{entry.prediction_date}</td>
                    <td>{entry.prediction_value}</td>
                    <td>{entry.view_or_analysis.toUpperCase()}</td>
                    <td>
                      <button
                        className="view-prediction"
                        onClick={() =>
                          handleViewPrediction(entry.prediction_id)
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* <div className="pagination">
          <span>
            Showing {indexOfFirstItem + 1} to{' '}
            {indexOfLastItem < filteredData.length ? indexOfLastItem : filteredData.length} of{' '}
            {filteredData.length} entries
          </span>
          <div className="pagination-controls">
            <button className="page-btn" onClick={handlePrevPage} disabled={currentPage === 1}>
              &lt;
            </button>
            <button className="page-btn active">{currentPage}</button>
            <button
              className="page-btn"
              onClick={handleNextPage}
              disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}
            >
              &gt;
            </button>
          </div>
          </div> */}
      </div>
    </div>
  );
};

export default HistoryPage;
