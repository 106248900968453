import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./HistoryPage.css";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import tickerData from "../company_tickers.json";
import { Helmet } from "react-helmet";
import SearchBox from "./SearchBox";
import { FiTrash, FiX } from "react-icons/fi";

const FavoritesPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const baseURL =
    process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
      ? "http://127.0.0.1:5000"
      : "https://flask-backend-52245432644.us-central1.run.app";
  const frontendBaseURL =
    process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
      ? "http://localhost:8080"
      : "https://marketcrunch.ai";

  const tickerLookup = Object.values(tickerData).reduce((acc, item) => {
    acc[item.ticker] = item.title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return acc;
  }, {});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const userId = localStorage.getItem("databaseUid");
    if (userId) {
      fetchFavorites(userId);
    } else {
      setError("User is not logged in");
      setLoading(false);
    }
  }, []);

  const handleRemoveFavorite = async (ticker) => {
    setLoading(true);
    const userId = localStorage.getItem("databaseUid");
    try {
      await axios.get(
        `${baseURL}/remove-from-favorites?uid=${userId}&ticker=${ticker}`
      );
      setError(null);
      fetchFavorites(userId);
    } catch (err) {
      setError(`Failed to remove ${ticker} from favorites. Please try again.`);
      setLoading(false);
    }
  };

  const addToFavorites = async (ticker) => {
    setLoading(true);
    const userId = localStorage.getItem("databaseUid");
    try {
      const baseURL =
        process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
          ? "http://127.0.0.1:5000"
          : "https://flask-backend-52245432644.us-central1.run.app";

      await axios.get(
        `${baseURL}/add-to-favorites?uid=${userId}&ticker=${ticker}`
      );
      setError(null);
      fetchFavorites(userId);
    } catch (err) {
      setError("Failed to add to favorites. Please try again.");
      setLoading(false);
    }
  };

  const fetchFavorites = async (userId) => {
    setLoading(true);
    try {
      const baseURL =
        process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
          ? "http://127.0.0.1:5000"
          : "https://flask-backend-52245432644.us-central1.run.app";

      const response = await axios.get(`${baseURL}/get-favorites`, {
        params: { uid: userId },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const fetchedData = response.data;

      if (fetchedData && Array.isArray(fetchedData.favorites)) {
        setData(fetchedData.favorites);
        setError(null);
      } else {
        setData([]);
        setError("You have no favorites! Add some to get started!");
      }
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch favorites data. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="history-page-container">
      <Helmet>
        <title>History - MarketCrunch AI</title>
        <meta
          name="description"
          content="Review your past stock market predictions with MarketCrunch AI."
        />
      </Helmet>
      <div className="history-container">
        <div className="header-row">
          <div className="right-side-controls">
            <SearchBox
              buttonText={"Add"}
              onSearch={addToFavorites}
              isLanding={true}
            />
          </div>
        </div>

        <div className="history-table-container">
          <table className="history-table">
            <thead>
              <tr>
                <th>Company</th>
                <th>Ticker</th>
                <th>Current Price</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    Loading data...
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    {error}
                  </td>
                </tr>
              ) : Array.isArray(data) && data.length === 0 ? (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No history found
                  </td>
                </tr>
              ) : (
                Array.isArray(data) &&
                data.map((entry, index) => (
                  <tr key={index} style={{ fontFamily: "Inter" }}>
                    <td>{tickerLookup[entry.ticker]}</td>
                    <td>{entry.ticker}</td>
                    <td
                      style={{
                        color:
                          parseFloat(entry.percent_change) > 0
                            ? "#139C8D"
                            : "#FF0000",
                      }}
                    >
                      {String(entry.price) === "null" ||
                      String(entry.percent_change) === "null"
                        ? "Unable to fetch live data"
                        : `${entry.price} (${entry.percent_change}%)`}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
                            ? window.open(
                                `${frontendBaseURL}/analyze?t=${entry.ticker}`
                              )
                            : window.open(
                                `${frontendBaseURL}/analyze?t=${entry.ticker}`
                              );
                        }}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#139C8D",
                          border: 0,
                          borderRadius: 5,
                          color: "white",
                          fontFamily: "Inter",
                          fontSize: 14,
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                      >
                        Analyze
                      </button>
                    </td>
                    <td>
                      <button
                        style={{
                          border: 0,
                          backgroundColor: "transparent",
                          cursor: "pointer",
                          display: "flex",
                        }}
                        onClick={() => handleRemoveFavorite(entry.ticker)}
                      >
                        <FiTrash size={20} />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FavoritesPage;
