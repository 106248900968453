import React, { useState, useEffect } from "react";
import { FaPlay } from "react-icons/fa"; // FontAwesome Play Icon
import "./ScreenersPage.css"; // Custom CSS for the Screeners Page
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase-config";
import UpgradePopup from "./UpgradePopup";
import { Helmet } from "react-helmet";

const ScreenersPage = () => {
  const baseURL =
    process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
      ? "http://127.0.0.1:5000"
      : "https://flask-backend-52245432644.us-central1.run.app";
  const [winnersData, setWinnersData] = useState([]);
  const [losersData, setLosersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("winners");
  const [user, setUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [filters, setFilters] = useState({
    ticker: "",
    priceChangePercentMin: "",
    priceChangePercentMax: "",
    volumeMin: "",
    volumeMax: "",
    confidenceMin: "",
    confidenceMax: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const response = await axios.get(`${baseURL}/check-for-subscription`, {
          params: { uid: localStorage.getItem("databaseUid") },
          headers: {
            "Content-Type": "application/json",
          },
        });
        setIsSubscribed(response.data.status);
      } else {
        setIsSubscribed(false);
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const fetchScreenersData = async () => {
      try {
        const baseURL =
          process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
            ? "http://127.0.0.1:5000"
            : "https://flask-backend-52245432644.us-central1.run.app";
        const response = await fetch(`${baseURL}/screeners`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();

        setWinnersData(result.predicted_top_winners || []);
        setLosersData(result.predicted_top_losers || []);
      } catch (err) {
        console.error("Error fetching screeners data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchScreenersData();
  }, []);

  useEffect(() => {
    // Apply filters based on the filters state
    const dataToFilter = activeTab === "winners" ? winnersData : losersData;
    const filtered = dataToFilter.filter((item) => {
      const matchesTicker = filters.ticker
        ? item.ticker.toLowerCase().includes(filters.ticker.toLowerCase())
        : true;
      const matchesPriceChangePercent =
        (!filters.priceChangePercentMin ||
          item.price_change_percent_actuals >=
            parseFloat(filters.priceChangePercentMin)) &&
        (!filters.priceChangePercentMax ||
          item.price_change_percent_actuals <=
            parseFloat(filters.priceChangePercentMax));
      const matchesVolume =
        (!filters.volumeMin || item.volume >= parseFloat(filters.volumeMin)) &&
        (!filters.volumeMax || item.volume <= parseFloat(filters.volumeMax));
      const matchesConfidence =
        (!filters.confidenceMin ||
          item.confidence >= parseFloat(filters.confidenceMin)) &&
        (!filters.confidenceMax ||
          item.confidence <= parseFloat(filters.confidenceMax));
      return (
        matchesTicker &&
        matchesPriceChangePercent &&
        matchesVolume &&
        matchesConfidence
      );
    });
    setFilteredData(filtered);
  }, [activeTab, filters, winnersData, losersData]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const computeRangeConfidence = (confidence) => {
    if (confidence < 30) return "Low";
    if (confidence >= 30 && confidence <= 50) return "Med";
    return "High";
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    // Use the components of the date to format it explicitly
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is zero-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  const formatVolume = (volume) => {
    return volume ? volume.toLocaleString() : "-";
  };
  return (
    <div className="screeners-page-container">
      <Helmet>
        <title>Screener: Best AI-based Predictive Stocks</title>
        <meta
          name="description"
          content="Get daily curated stock picks driven by our AI models. Uncover potential market winners with MarketCrunch AI."
        />
      </Helmet>
      {!isSubscribed && isPopupOpen && (
        <UpgradePopup isOpen={true} handleClose={() => setPopupOpen(false)} />
      )}
      <br />
      <p className="analysis-text">
        Our AI engine analyzes hundreds of stocks daily. We list our best models
        below based on their accuracy over last 90-day prediction performance.{" "}
        <br />
        Press{" "}
        <FaPlay
          style={{ color: "#007bff", fontSize: "12px", marginLeft: "5px" }}
        />{" "}
        to analyze a ticker ({process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE}{" "}
        credit)
        <br />
        <br />
      </p>

      <div className="tabs">
        <div
          className={`tab ${activeTab === "winners" ? "active" : ""}`}
          onClick={() => handleTabClick("winners")}
        >
          Daily Movers
        </div>
        {/* <div className={`tab ${activeTab === 'losers' ? 'active' : ''}`} onClick={() => handleTabClick('losers')}>
          Predicted Losers
        </div> */}
      </div>

      {/* <div className="screeners-filter-container">
        <h3>Filter Stocks</h3>
        <div className="screeners-filters">
          <div className="screeners-filter-group">
            <label>Ticker</label>
            <input
              type="text"
              name="ticker"
              value={filters.ticker}
              onChange={handleFilterChange}
              placeholder="Search by ticker"
            />
          </div>
          <div className="screeners-filter-group">
            <label>Price Change %</label>
            <input
              type="number"
              name="priceChangePercentMin"
              value={filters.priceChangePercentMin}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              type="number"
              name="priceChangePercentMax"
              value={filters.priceChangePercentMax}
              onChange={handleFilterChange}
              placeholder="Max"
            />
          </div>
          <div className="screeners-filter-group">
            <label>Volume</label>
            <input
              type="number"
              name="volumeMin"
              value={filters.volumeMin}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              type="number"
              name="volumeMax"
              value={filters.volumeMax}
              onChange={handleFilterChange}
              placeholder="Max"
            />
          </div>
          <div className="screeners-filter-group">
            <label>Confidence (%)</label>
            <input
              type="number"
              name="confidenceMin"
              value={filters.confidenceMin}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              type="number"
              name="confidenceMax"
              value={filters.confidenceMax}
              onChange={handleFilterChange}
              placeholder="Max"
            />
          </div>
        </div>
      </div> */}

      <div className="table-container">
        <table className="screeners-table">
          <thead>
            <tr>
              {isSubscribed ? <th>Ticker</th> : <th>🔒 Ticker</th>}
              {
                <th>As of Date</th>
                /*<th>Predicted Value</th>
              <th>{activeTab === 'winners' ? 'Predicted Gain $' : 'Predicted Loss $'}</th>
              <th>{activeTab === 'winners' ? 'Predicted %' : 'Predicted Loss %'}</th>*/
              }
              <th>Confidence Level</th>
              {/* <th>Date of Actuals</th> */}
              <th>Direction Accuracy</th>
              <th>Open-Close Accuracy</th>
              {isSubscribed ? <th>Prev. Close</th> : <th>🔒 Prev. Close</th>}
              <th>Prev. Close %</th>
              {/* <th>Prev. Volume</th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  Loading data...
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No data available
                </td>
              </tr>
            ) : (
              filteredData.map((entry, index) => (
                <tr key={index}>
                  <td
                    onClick={() => (!isSubscribed ? setPopupOpen(true) : null)}
                    className="left-aligned"
                    style={{
                      filter: isSubscribed ? "blur(0px)" : "blur(5px)",
                      // pointerEvents: isSubscribed ? "auto" : "none",
                      userSelect: isSubscribed ? "auto" : "none",
                      cursor: !isSubscribed ? "pointer" : "auto",
                    }}
                  >
                    <div className="ticker-container">
                      {entry.ticker || "-"}
                      <button
                        className="play-button"
                        onClick={() =>
                          !isSubscribed
                            ? setPopupOpen(true)
                            : window.open(
                                `https://marketcrunch.ai/analyze?t=${entry.ticker}`,
                                "_blank"
                              )
                        }
                      >
                        <FaPlay
                          style={{
                            color: "#007bff",
                            fontSize: "12px",
                            marginLeft: "1px",
                          }}
                        />
                      </button>
                    </div>
                  </td>
                  {
                    <td>{formatDate(entry.prediction_date)}</td>
                    /*<td className="numeric-column">${entry.predicted_close?.toFixed(2) || '-'}</td>
                  <td className="numeric-column">{entry.predicted_gain_value?.toFixed(2) || '-'}</td> 
                  <td className="numeric-column">{entry.predicted_gain_percent?.toFixed(2) || '-'}%</td>*/
                  }
                  <td>{computeRangeConfidence(entry.confidence)}</td>
                  {/* <td>{formatDate(entry.date_of_actuals)}</td> */}
                  <td className="numeric-column">
                    {entry.sign_accuracy !== undefined &&
                    entry.sign_accuracy !== null
                      ? `${entry.sign_accuracy.toFixed(1)}%`
                      : "-"}
                  </td>
                  <td className="numeric-column">
                    {entry.open_close_accuracy !== undefined &&
                    entry.open_close_accuracy !== null
                      ? `${entry.open_close_accuracy.toFixed(1)}%`
                      : "-"}
                  </td>
                  <td
                    onClick={() => (!isSubscribed ? setPopupOpen(true) : null)}
                    className="numeric-column"
                    style={{
                      filter: isSubscribed ? "blur(0px)" : "blur(5px)",
                      // pointerEvents: isSubscribed ? "auto" : "none",
                      userSelect: isSubscribed ? "auto" : "none",
                      cursor: !isSubscribed ? "pointer" : "auto",
                    }}
                  >
                    ${entry.actual_close?.toFixed(2) || "-"}
                  </td>
                  <td className="numeric-column">
                    {entry.price_change_percent_actuals !== undefined &&
                    entry.price_change_percent_actuals !== null
                      ? `${(entry.price_change_percent_actuals * 100).toFixed(
                          2
                        )}%`
                      : "-"}
                  </td>
                  {/* <td className="numeric-column">{formatVolume(entry.volume)}</td> */}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScreenersPage;
