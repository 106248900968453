import React, { useState, useEffect } from "react";
import './TestimonialSection.css';

const testimonialsData = [
  {
      text: "After many months of unsuccessful trading, I came across MarketCrunch and it really provided deep insights into near term price movements.",
      author: "Julio S."
  },
  {
      text: "We live in the age of machines: hedge-funds and institutions dictate price movements. MarketCrunch helps the little guys by using machines to provide simple insights.",
      author: "K. Coy"
  },
  {
      text: "Exceptional insights and outstanding support. MarketCrunch helps the little guys.",
      author: "S. Varma"
  },
  {
      text: "For me, MarketCrunch revolutionized the way I approach trading. Now I come here first, before hitting the Trade button",
      author: "Client"
  },
  {
      text: "A game-changer for small investors. MarketCrunch leveled the playing field.",
      author: "J.P."
  },
  {
      text: "The user interface is intuitive and the data is spot on. Highly recommend MarketCrunch.",
      author: "A. Powar"
  },
  // Add more testimonials as needed
];

const TestimonialSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = testimonialsData.length;

    // Automatic scrolling every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        }, 5000); // Adjusted to 5 seconds for automatic sliding
        return () => clearInterval(interval);
    }, [totalSlides]);

    // Only show the current testimonial
    const currentTestimonial = testimonialsData[currentSlide];

    return (
        <div id="testimonials" className="testimonials-partnerships-section">
            <div className="testimonials-container">
                <div className="carousel-content">
                    <div className='testimonial-column'>
                        <div className="testimonial">
                            <p>"{currentTestimonial.text}"</p>
                            <p className="author">- {currentTestimonial.author}</p>
                        </div>
                    </div>
                </div>
                <div className="carousel-indicators">
                    {Array.from({ length: totalSlides }).map((_, index) => (
                        <span
                            key={index}
                            className={`indicator-dot ${index === currentSlide ? 'active' : ''}`}
                            onClick={() => setCurrentSlide(index)}
                            aria-label={`Go to testimonials slide ${index + 1}`}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialSection;
