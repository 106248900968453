import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { DateTime } from "luxon";
import styles from "./LivePriceCard.module.css";

const LivePriceCard = ({ ticker, width, lastClosePrice }) => {
  const [isMarketOpen, setIsMarketOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [tradingDay, setTradingDay] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState("1D");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const today = new Date();

  const getChartTitle = (selectedInterval) => {
    switch (selectedInterval) {
      case "1D":
        if (!tradingDay) return "";
        return `on ${tradingDay.substring(5)}-${tradingDay.substring(0, 4)}`;
      case "5D":
        return "For the Last 5 Days";
      case "1M":
        return "For the Last Month";
      case "6M":
        return "For the Last 6 Months";
      case "1Y":
        return "For the Last Year";
    }
  };

  const checkMarketStatus = () => {
    const nowEastern = DateTime.now().setZone("America/New_York");
    const marketOpen = nowEastern.set({
      hour: 9,
      minute: 30,
      second: 0,
      millisecond: 0,
    });
    const marketClose = nowEastern.set({
      hour: 16,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return nowEastern >= marketOpen && nowEastern <= marketClose;
  };

  const fetchLivePrice = async () => {
    setLoading(true);
    setError(null);
    try {
      const baseURL =
        process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
          ? "http://127.0.0.1:5000"
          : "https://flask-backend-52245432644.us-central1.run.app";
      const response = await axios.get(`${baseURL}/get-live-data`, {
        params: { ticker, selectedInterval },
      });
      if (response.data && response.data.data.length > 0) {
        const formattedData = response.data.data.map((row) => ({
          Time: row.time,
          Value: row.value !== null ? Number(row.value) : null, // Ensure null values are preserved
        }));
        setGraphData(formattedData);
        setTradingDay(response.data.trading_day);
      }
      setIsMarketOpen(checkMarketStatus());
    } catch (error) {
      console.error("Error fetching live price:", error);
      setError("Failed to load data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLivePrice();
    let intervalId;
    if (checkMarketStatus() && selectedInterval === "1D") {
      intervalId = setInterval(fetchLivePrice, 60000);
    }
    return () => intervalId && clearInterval(intervalId);
  }, [ticker, selectedInterval]);

  const previousCloseData = graphData.map((point) => ({
    ...point,
    previousClose: lastClosePrice,
  }));

  const formatValue = (value) =>
    typeof value === "number" && !isNaN(value) ? `$${value.toFixed(2)}` : "N/A";

  const formatTime = (time) => {
    if (selectedInterval === "1D") return time; // HH:MM
    return DateTime.fromFormat(time, "yyyy-MM-dd").toFormat("MM/dd/yy");
  };

  return (
    <div
      className="live-price-card"
      style={{
        width: width < 485 ? "100%" : "calc(50% - 10px)",
        height: "475px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={styles.titleContainer}>
        <h2 className={styles.livePriceCardTitle} style={{ fontSize: "14px" }}>
          {ticker.toUpperCase()} {getChartTitle(selectedInterval)}
        </h2>
      </div>
      <div style={{ flex: 1, minHeight: 0 }}>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            Loading data...
          </div>
        ) : error ? (
          <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
            {error}
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={previousCloseData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="Time"
                tickFormatter={formatTime}
                interval={
                  selectedInterval === "1D" ? 59 : width < 485 ? 100 : 50
                } // ~1-hour intervals for 1D
                domain={
                  selectedInterval === "1D"
                    ? ["09:30", "16:00"]
                    : ["auto", "auto"]
                } // Full market hours
              />
              <YAxis
                domain={["auto", "auto"]}
                tickFormatter={(value) => `$${value}`}
              />
              <Tooltip
                formatter={(value, name) => [formatValue(value), name]}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="Value"
                stroke="#139C8D"
                name="Price"
                strokeWidth={3}
                dot={false}
                connectNulls={true}
              />
              {selectedInterval === "1D" && (
                <ReferenceLine
                  y={lastClosePrice}
                  stroke="#139C8D"
                  strokeWidth={3}
                  label={{
                    value: `Prev. Close: ${lastClosePrice.toFixed(2)}`,
                    position: "insideBottomLeft",
                    offset: 10,
                    style: { fill: "black", fontSize: "0.8em" },
                  }}
                  ifOverflow="extendDomain"
                  strokeDasharray="3 3"
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default LivePriceCard;

{
  /* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          margin: "0px auto 10px auto",
        }}
      >
        {["1D", "5D", "1M", "6M", "1Y"].map((interval) => (
          <span
            key={interval}
            className={
              selectedInterval === interval
                ? styles.selectedIntervalButton
                : styles.intervalButton
            }
            onClick={() => setSelectedInterval(interval)}
          >
            {interval}
          </span>
        ))}
      </div> */
}
