// Disclaimer.jsx
import React, { useEffect, useRef } from 'react';
import './Disclaimer.css';

const Disclaimer = ({ isOpen, onClose, disclaimerButtonRef }) => {
  const closeButtonRef = useRef(null);

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent background scrolling when the popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Focus the close button for accessibility
      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Return focus to the disclaimer button when closing
  const handleClose = () => {
    onClose();
    if (disclaimerButtonRef && disclaimerButtonRef.current) {
      disclaimerButtonRef.current.focus();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='disclaimer'>
        <div className="popup-overlay" onClick={handleClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button
          className="close-button"
          onClick={handleClose}
          ref={closeButtonRef}
          aria-label="Close"
        >
          &times;
        </button>

        <p><strong>Disclaimer:</strong> The information provided by MarketCrunch AI is for informational purposes only. MarketCrunch AI is not registered as a securities broker-dealer or an investment adviser. Nothing contained herein shall be construed as securities brokerage, investment, tax, accounting, or legal advice, nor as an offer or solicitation of an offer to sell or buy any security, or as an endorsement, recommendation, or sponsorship of any company, security, or fund.</p>

        <p>MarketCrunch AI does not and cannot assess, verify, or guarantee the adequacy, accuracy, or completeness of any information, nor does it evaluate the suitability or profitability of any particular investment. Users bear full responsibility for their own investment research and decisions. They are encouraged to seek the advice of qualified professionals and should fully understand all risks before investing. MarketCrunch AI makes no warranty, express or implied, regarding the solvency, financial condition, or investment advisability of any security mentioned in its content, communications, or websites.</p>

        <p>MarketCrunch AI shall not be liable for any direct or consequential loss arising from any use of this information. The information provided is not intended to serve as the basis for any investment decision, nor should it be construed as tailored advice meeting the investment needs of any particular individual. Past performance is not necessarily indicative of future returns. Any reliance upon information from MarketCrunch AI is at your own risk.</p>

      </div>
    </div>
    </div>
  );
};

export default Disclaimer;
