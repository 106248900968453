import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UpgradePopup.css";
import axios from "axios";

const UpgradePopup = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();
  const baseURL =
    process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
      ? "http://127.0.0.1:5000"
      : "https://flask-backend-52245432644.us-central1.run.app";
  const [isWeekly, setIsWeekly] = useState(true); // State for billing cycle toggle
  const [selectedPlan, setSelectedPlan] = useState(1); // State for selected plan
  const [hasActiveSub, setHasActiveSub] = useState(false);

  useEffect(() => {
    async function checkForSub() {
      const hasActiveSub = await axios.get(
        `${baseURL}/check-for-subscription`,
        {
          params: { uid: localStorage.getItem("databaseUid") },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setHasActiveSub(hasActiveSub.data.status);
    }
    checkForSub();
  }, []);

  useEffect(() => {
    // if (hasActiveSub) {
    //   setIsWeekly(false);
    // }
  }, [hasActiveSub]);

  if (!isOpen) {
    return null; // Do not render if the popup is closed
  }

  // Toggle between monthly and yearly pricing
  const handleBillingToggle = () => {
    setIsWeekly((prev) => !prev);
  };

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle confirmation
  const handleConfirm = () => {
    let pid;
    if (selectedPlan === 0) {
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? (pid = "RzV0r36Qwg7DeW")
        : (pid = "RAZMTyeZ0I3liW");
      navigate(`/payment?pid=${pid}&isRecurring=${false}`);
    } else if (selectedPlan === 1) {
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? (pid = "RzV1HgTcYrTiEh")
        : (pid = "RzV4Xe3SY0PgLP");
      navigate(`/payment?pid=${pid}&isRecurring=${isWeekly}`);
    } else if (selectedPlan === 2) {
      process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
        ? (pid = "S0PshMYLSoP1oe")
        : (pid = "RzV6zcdySGNVdM");
      navigate(`/payment?pid=${pid}&isRecurring=${isWeekly}`);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>
            {/* {hasActiveSub
              ? "Get credits for AI Analysis"
              : "Unlock Your Trading Edge "} */}
            Unlock Your Trading Edge
          </h2>
          <p className="popup-header-text">
            Select a plan and continue accessing premium AI insights tailored to
            your trading needs.
            <br />
            No commitments, cancel anytime.
          </p>
          <br />
          <p id="popup-header-text"></p>
          <button className="close-button" onClick={handleClose}>
            ✕
          </button>
        </div>

        <div className="plan-options">
          <button
            className={`plan basic ${selectedPlan === 0 ? "active" : ""}`}
            onClick={() => handlePlanSelect(0)}
          >
            {" "}
            {isWeekly ? (
              <div className="upgrade-popup-ribbon-onetime">Pay-as-you-Go</div>
            ) : (
              ""
            )}
            <h3>{isWeekly ? "$4.99" : "$4.99"}</h3>
            {/* <p>{isWeekly ? 'Billed Weekly' : 'One Time'}</p> */}
            {/* <h3>$4.99</h3> */}
            <ul>
              <li>✔ Novice traders</li>
              <li>
                {isWeekly ? (
                  <span>
                    ✔{" "}
                    <span className={`${selectedPlan === 0 ? "bold" : ""}`}>
                      Latte-priced insights ☕
                    </span>{" "}
                  </span>
                ) : (
                  "✔ Easiest to Start"
                )}
              </li>
              <li>
                ✔{" "}
                {isWeekly ? (
                  <span className={`${selectedPlan === 0 ? "bold" : ""}`}>
                    3 credits (one-time)
                  </span>
                ) : (
                  <span className={`${selectedPlan === 0 ? "bold" : ""}`}>
                    3 credits
                  </span>
                )}
              </li>
              <li>✘ No AI-Picks access</li>
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 1 ? "active" : ""}`}
            onClick={() => handlePlanSelect(1)}
          >
            {isWeekly ? (
              <div className="upgrade-popup-ribbon">Weekly (Popular!)</div>
            ) : (
              ""
            )}
            <h3>{isWeekly ? <>$8.99</> : <>$11.99</>}</h3>
            {/*<p>{isWeekly ? 'Billed Weekly' : 'One Time'}</p>*/}
            {/* <h3>$11.99</h3> */}
            <ul>
              <li>✔ Active traders</li>
              <li>
                {isWeekly ? (
                  <span>
                    ✔{" "}
                    <span className={`${selectedPlan === 1 ? "bold" : ""}`}>
                      Saves 23% per credit
                    </span>{" "}
                  </span>
                ) : (
                  <span>
                    ✔{" "}
                    <span className={`${selectedPlan === 1 ? "bold" : ""}`}>
                      Buy more, Save 28%
                    </span>
                  </span>
                )}
              </li>
              <li>
                ✔{" "}
                {isWeekly ? (
                  <span className={`${selectedPlan === 1 ? "bold" : ""}`}>
                    7 credits/week
                  </span>
                ) : (
                  <span className={`${selectedPlan === 1 ? "bold" : ""}`}>
                    10 credits
                  </span>
                )}
              </li>
              <li>✔ AI-Picks access</li>
              {/* isWeekly ? <li>✔ <span className={`${selectedPlan === 1 ? 'bold' : ''}`}>3 days free!</span></li> : '' */}
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 2 ? "active" : ""}`}
            onClick={() => handlePlanSelect(2)}
          >
            {isWeekly ? (
              <div className="upgrade-popup-ribbon">Monthly</div>
            ) : (
              ""
            )}
            <h3>{isWeekly ? <>$19.99</> : <>$18.99</>}</h3>
            {/*<p>{isWeekly ? 'Billed Weekly' : 'One Time'}</p>*/}
            {/* <h3>$18.99</h3> */}
            <ul>
              <li>✔ Casual Traders</li>
              <li>
                {isWeekly ? (
                  <span>
                    ✔{" "}
                    <span className={`${selectedPlan === 2 ? "bold" : ""}`}>
                      Saves 14% per credit
                    </span>{" "}
                  </span>
                ) : (
                  <span>
                    ✔{" "}
                    <span className={`${selectedPlan === 2 ? "bold" : ""}`}>
                      Save 29%
                    </span>{" "}
                    (or $7.5)
                  </span>
                )}
              </li>
              <li>
                ✔{" "}
                {isWeekly ? (
                  <span className={`${selectedPlan === 2 ? "bold" : ""}`}>
                    14 credits/month
                  </span>
                ) : (
                  <span className={`${selectedPlan === 2 ? "bold" : ""}`}>
                    21 credits
                  </span>
                )}
              </li>
              <li>✔ AI-Picks access</li>
              {/* isWeekly ? <li>✔ <span className={`${selectedPlan === 2 ? 'bold' : ''}`}>7 days free!</span></li> : ''*/}
            </ul>
          </button>
        </div>

        {/* {!hasActiveSub ? (
          <div className="billing-toggle">
            <span className="slider-text">Pay-per-use</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={isWeekly}
                onChange={handleBillingToggle}
              />
              <span className="slider round"></span>
            </label>
            <span className="slider-text">Subscribe & Save</span>
          </div>
        ) : (
          <></>
        )} */}
        <div
          className="popup-footer"
          style={{ pointerEvents: "auto", zIndex: 3000, position: "relative" }}
        >
          <button
            className="cancel-button"
            onClick={handleClose}
            style={{ pointerEvents: "auto", zIndex: 3000 }}
          >
            Cancel
          </button>
          <button
            className="confirm-button"
            onClick={handleConfirm}
            style={{ pointerEvents: "auto", zIndex: 3000 }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePopup;
