import React from 'react';
import './RedditSection.css';
import redditDesktopImg from '../assets/images/reddit_desktop.png'; // Adjust the path as needed
import redditMobileImg from '../assets/images/reddit_mobile.png'; // Adjust the path as needed

const RedditSection = () => {
  return (
    <div id="home" className="reddit-section">
      <div className="reddit-image-container">
        <img
          src={redditDesktopImg}
          alt="Reddit Desktop View"
          className="reddit-image reddit-desktop"
        />
        <img
          src={redditMobileImg}
          alt="Reddit Mobile View"
          className="reddit-image reddit-mobile"
        />
      </div>
    </div>
  );
};

export default RedditSection;