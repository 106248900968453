// MobileMenu.jsx
import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import './MobileMenu.css'; // Import the corresponding CSS

const MobileMenu = ({ isOpen, toggleMenu }) => {
  function adjustLayout() {
    const menu = document.querySelector('.menu');
    const analysis = document.querySelector('.analysis');

    if (menu && analysis) {
      menu.style.marginBottom = '0';
      analysis.style.marginTop = '0';
    }
  }

  useEffect(() => {
    adjustLayout();
  }, []);

  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      {/* Close Icon */}
      <div className="close-icon" onClick={toggleMenu}>
        <FaTimes />
      </div>
      
      {/* Navigation Links */}
      <div className="mobile-menu-links">
        {/* <a href="#home" onClick={toggleMenu}>Home</a> */}
        <a href="#home" onClick={toggleMenu}>Product</a>
        <a href="#testimonials" onClick={toggleMenu}>Testimonials</a>
        <a href="#team" onClick={toggleMenu}>Team</a>
      </div>
    </div>
  );
};

export default MobileMenu;
