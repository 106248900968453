import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import "./Dashboard.css";
import "./AccountPage.css";
import { auth } from "../firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import axios from "axios";

const AccountPage = ({ setCredits }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [renewalDate, setRenewalDate] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const baseURL =
    process.env.REACT_APP_ENVIRONMENT_TYPE === "DEV"
      ? "http://127.0.0.1:5000"
      : "https://flask-backend-52245432644.us-central1.run.app";
  const dropdownRef = useRef(null);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle sorting by latest or oldest
  useEffect(() => {
    if (sortOrder) {
      const sortedData = [...filteredData].sort((a, b) => {
        const dateA = new Date(a.researchDate);
        const dateB = new Date(b.researchDate);
        return sortOrder === "latest" ? dateB - dateA : dateA - dateB;
      });
      setFilteredData(sortedData);
    }
  }, [sortOrder, filteredData]);

  useEffect(() => {
    if (!user) return;
    const fetchCredits = async () => {
      const userId = localStorage.getItem("uid");
      const response = await fetch(`${baseURL}/get-credits`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userId}:${user.uid}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch credits. Status: ${response.status}`);
      }

      const result = await response.json();
      localStorage.setItem("credits", result.credits_available);
      setCredits(result.credits_available);
    };
    fetchCredits();
  }, [user]);

  // Fetch history data for the logged-in user
  const fetchData = async (userId) => {
    try {
      const response = await axios.get(`${baseURL}/get-payment-history`, {
        params: { uid: userId }, // Ensure 'user_id' is sent as the correct parameter
        headers: {
          "Content-Type": "application/json",
        },
      });
      const fetchedData = response.data;

      // Ensure fetchedData is an array before setting state
      if (fetchedData && Array.isArray(fetchedData.payment_history)) {
        setData(fetchedData.payment_history);
        setFilteredData(fetchedData.payment_history);
      } else {
        setData([]);
        setFilteredData([]);
        setError("Invalid data format received");
      }

      setLoading(false);
    } catch (err) {
      setError("Failed to fetch history data. Please try again.");
      setLoading(false);
    }
  };

  // Handle pagination controls
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCancelSubscription = async (userId) => {
    closeCancelSubscriptionPopup();
    try {
      var cancelSubButton = document.getElementById("cancel-sub-button");
      cancelSubButton.textContent = "Cancelling...";
      const response = await axios.get(`${baseURL}/cancel-subscription`, {
        params: { uid: userId }, // Ensure 'user_id' is sent as the correct parameter
        headers: {
          "Content-Type": "application/json",
        },
      });
      cancelSubButton.textContent = "Cancelled!";
      setTimeout(() => {
        cancelSubButton.textContent = "Cancel Subscription";
      }, 5000);
      setIsSubscribed(false);
    } catch (err) {
      setError("Failed to cancel subscription. Please try again.");
      setLoading(false);
    }
  };

  const openCancelSubscriptionPopup = () => {
    var popupOverlay = document.getElementById("account-popup-overlay");
    var popup = document.getElementById("account-popup");
    popupOverlay.style.display = "block";
    popup.style.display = "block";
  };

  const closeCancelSubscriptionPopup = () => {
    var popupOverlay = document.getElementById("account-popup-overlay");
    var popup = document.getElementById("account-popup");
    popupOverlay.style.display = "none";
    popup.style.display = "none";
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        fetchData(localStorage.getItem("databaseUid"));
        const hasActiveSub = await axios.get(
          `${baseURL}/check-for-subscription`,
          {
            params: { uid: localStorage.getItem("databaseUid") },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsSubscribed(hasActiveSub.data.status);
        setRenewalDate(hasActiveSub.data.renewal_date);
      } else {
        setIsSubscribed(false);
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);

  return (
    <>
      <div id="account-popup-overlay"></div>
      <div id="account-popup">
        <span>Are you sure you want to cancel your subscription?</span>
        <div>
          <button onClick={() => closeCancelSubscriptionPopup()}>No</button>
          <button
            onClick={() =>
              handleCancelSubscription(localStorage.getItem("databaseUid"))
            }
          >
            Yes
          </button>
        </div>
      </div>
      <div className="dashboard-search-container accounts-page">
        <h2 className="profile-header">Profile</h2>
        <div className="profile-info-container">
          <span>Name</span>
          <div>
            <input
              type="text"
              value={user ? user.displayName : "No user found"}
              disabled
            ></input>
            <span
              style={{
                color: "gray",
                fontWeight: "400",
                marginLeft: "1em",
                fontSize: 15,
              }}
            >
              <em>as it appears on Google</em>
            </span>
          </div>
        </div>
        <div className="profile-info-container">
          <span>Email</span>
          <div>
            <input
              type="text"
              value={user ? user.email : "No user found"}
              disabled
            ></input>
            <span
              style={{
                color: "gray",
                fontWeight: "400",
                marginLeft: "1em",
                fontSize: 15,
              }}
            >
              <em>as it appears on Google</em>
            </span>
          </div>
        </div>
        <div className="profile-info-container">
          <span>Subscription</span>
          <span
            className="subscription-status"
            style={{
              color: isSubscribed ? "white" : "gray",
              backgroundColor: isSubscribed
                ? "rgba(19, 156, 141, 0.6)"
                : "rgba(239, 239, 239, 0.6)",
            }}
          >
            {isSubscribed ? `Active: Renews on ${renewalDate}` : "Inactive"}
          </span>
          <a
            id="cancel-sub-button"
            style={{ display: isSubscribed ? "block" : "none" }}
            onClick={() => openCancelSubscriptionPopup()}
          >
            Cancel Subscription
          </a>
        </div>

        <div className="top-predictions-container">
          {/* Inside the box: Header, Search, Filter */}
          <div className="header-row">
            <h2 className="box-headline">Payment History</h2>

            <div className="right-side-controls">
              {/* Dropdown for sorting */}
              {/* <select
                  className="sort-dropdown"
                  value={sortOrder}
                  onChange={handleSortChange}
                >
                  <option value="latest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select> */}
            </div>
          </div>

          <div className="top-predictions-table-container">
            <table className="top-predictions-table">
              <thead>
                <tr>
                  <th>Payment Date</th>
                  <th>Total</th>
                  <th>Credits Received</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      Loading data...
                    </td>
                  </tr>
                ) : currentData.length === 0 ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      No payments found
                    </td>
                  </tr>
                ) : (
                  currentData.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.payment_timestamp}</td>
                      <td>{entry.payment_amount}</td>
                      <td>{entry.credits_received}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="pagination">
            <span>
              Showing {indexOfFirstItem + 1} to{" "}
              {indexOfLastItem < filteredData.length
                ? indexOfLastItem
                : filteredData.length}{" "}
              of {filteredData.length} entries
            </span>
            <div className="pagination-controls">
              <button
                className="page-btn"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              <button className="page-btn active">{currentPage}</button>
              <button
                className="page-btn"
                onClick={handleNextPage}
                disabled={
                  currentPage >= Math.ceil(filteredData.length / itemsPerPage)
                }
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPage;
